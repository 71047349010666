import * as React from 'react'
import { LargerText } from '../../../components/Typography'
import { Button } from '../../../components/Forms'
import { styled } from '../../../../lib/styled-components'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'

interface IProps {
  onNext: () => any
}

export const RightContent = ({ onNext }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()

  return (
    <Container>
      <Top>
        <LargerText bold centered>
          <p {...h(__('puzzles.02.right.headline'))} />

          <Image src="/images/puzzles/2/right.webp" />
        </LargerText>
      </Top>

      <Button onClick={onNext}>{__('puzzles.02.right.next')}</Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Image = styled.img`
  width: 313px;
`
