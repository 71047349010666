import * as React from 'react'
import { Button } from '../../components/Forms'
import { styled } from '../../../lib/styled-components'
import { Link } from 'react-router-dom'

interface IProps {
  number: string
  label: string
  path: string
}

export const PuzzleItem = ({ number, label, path }: IProps) => {
  return (
    <Container as={Link} to={path}>
      <div>{number}</div>
      <div>{label}</div>
    </Container>
  )
}

const Container = styled(Button)`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 285px;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`
