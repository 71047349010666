import * as React from 'react'
import { css, styled } from '../../../../lib/styled-components'
import { useCallback } from 'react'

interface IProps {
  cells?: [string, number][]
  onCellClick?: (row: string, column: number) => any
}

export const Grid = ({ cells, onCellClick }: IProps) => {
  const handleCellClick = useCallback((row: string, column: number) => {
    onCellClick?.(row, column)
  }, [])
  const isCellSelected = useCallback(
    (row: string, column: number) => {
      const cell = cells.find(([r, c]) => r === row && c === column)
      return cells.indexOf(cell) !== -1
    },
    [cells]
  )
  return (
    <Container>
      <GridElement>
        <LabelCell>
          <Label>A</Label>
        </LabelCell>
        <Cell firstRow firstInRow onClick={handleCellClick.bind(null, 'A', 1)} selected={isCellSelected('A', 1)}></Cell>
        <Cell firstRow onClick={handleCellClick.bind(null, 'A', 2)} selected={isCellSelected('A', 2)}></Cell>
        <Cell firstRow onClick={handleCellClick.bind(null, 'A', 3)} selected={isCellSelected('A', 3)}></Cell>

        <LabelCell>
          <Label>B</Label>
        </LabelCell>
        <Cell firstInRow onClick={handleCellClick.bind(null, 'B', 1)} selected={isCellSelected('B', 1)}></Cell>
        <Cell onClick={handleCellClick.bind(null, 'B', 2)} selected={isCellSelected('B', 2)}></Cell>
        <Cell onClick={handleCellClick.bind(null, 'B', 3)} selected={isCellSelected('B', 3)}></Cell>

        <LabelCell>
          <Label>C</Label>
        </LabelCell>
        <Cell firstInRow onClick={handleCellClick.bind(null, 'C', 1)} selected={isCellSelected('C', 1)}></Cell>
        <Cell onClick={handleCellClick.bind(null, 'C', 2)} selected={isCellSelected('C', 2)}></Cell>
        <Cell onClick={handleCellClick.bind(null, 'C', 3)} selected={isCellSelected('C', 3)}></Cell>

        <div></div>
        <LabelCell>
          <Label>1</Label>
        </LabelCell>
        <LabelCell>
          <Label>2</Label>
        </LabelCell>
        <LabelCell>
          <Label>3</Label>
        </LabelCell>
      </GridElement>
    </Container>
  )
}

const Container = styled.div``
const GridElement = styled.div`
  display: grid;
  grid-template-columns: 35px calc(var(--webkit-footer-gap) * 0.08) calc(var(--webkit-footer-gap) * 0.08) calc(
      var(--webkit-footer-gap) * 0.08
    );
  grid-template-rows:
    calc(var(--webkit-footer-gap) * 0.08) calc(var(--webkit-footer-gap) * 0.08) calc(var(--webkit-footer-gap) * 0.08)
    35px;
  transform: rotate(-45deg);
  margin-top: 30px;
`

const LabelCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Label = styled.div`
  background: ${({ theme }) => theme.textColor};
  color: ${({ theme }) => theme.buttonBackgroundColor};
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;

  justify-content: center;
  font-family: ${({ theme }) => theme.boldFontFamily};
  transform: rotate(45deg);
`
const Cell = styled.div<{ selected?: boolean; firstRow?: boolean; firstInRow?: boolean }>`
  background: ${({ theme, selected }) => (selected ? theme.gold : theme.buttonBackgroundColor)};
  border-right: 1px solid ${({ theme }) => theme.textColor};
  border-bottom: 1px solid ${({ theme }) => theme.textColor};
  ${({ firstInRow, theme }) =>
    firstInRow &&
    css`
      border-left: 1px solid ${theme.textColor};
    `};
  ${({ firstRow, theme }) =>
    firstRow &&
    css`
      border-top: 1px solid ${theme.textColor};
    `};
`
