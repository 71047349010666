import * as React from 'react'
import { LargerText, Text } from '../../../components/Typography'
import { Button } from '../../../components/Forms'
import { styled } from '../../../../lib/styled-components'
import { Choice } from './Choice'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'

interface IProps {
  onNext: () => any
}

export const RightContent = ({ onNext }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  return (
    <Container>
      <Top>
        <LargerText bold centered>
          <p>
            <strong {...h(__('puzzles.04.right.headline'))} />
          </p>
        </LargerText>
        <Text bold centered>
          <p {...h(__('puzzles.04.right.text'))} />
        </Text>

        <ImageContainer>
          <Image src="/images/puzzles/4/rosemary.webp" />
        </ImageContainer>
      </Top>

      <Button onClick={onNext}>{__('puzzles.04.right.next')}</Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Image = styled.img`
  width: 100%;
  max-height: 100%;
  object-fit: contain;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  padding: 16px;
  width: 70%;
  height: 160px;
`
