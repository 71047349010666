export const Locale = {
  name: 'Macedonian',
  chooseYourLanguage: 'Choose your language',
  shared: {
    imprint: 'ОЗНАКА/ПРИВАТНОСТ ',
    backToMainMenu: 'ВРАЌАЊЕ НА ГЛАВНОТО МЕНИ',
    backToHowToPlay: 'Back to "how to play"',
  },
  ageCheck: {
    line1:
      'ЗА ДА ПРИСТАПИТЕ ДО ОВАА СТРАНИЦА, МОРА ДА СТЕ БАРЕМ НА МИНИМАЛНАТА ВОЗРАСТ ЗА КОЈА Е ДОЗВОЛЕНО КОНСУМАЦИЈА НА АЛКОХОЛ ВО ВАШАТА ЗЕМЈА ИЛИ ДА СТЕ ПОСТАРИ.',
    line2: 'ВНЕСЕТЕ ГО ВАШИОТ ДАТУМ НА РАЃАЊЕ:',
    invalidDate: 'Invalid date',
    minimumAge: 'МОРА ДА БИДЕТЕ 18 ИЛИ ПОГОДИ ЗА ПРИСТАП НА СТРАНАВА.',
    month: 'МЕСЕЦ',
    day: 'ДЕН',
    year: 'ГОДИНА',
    submit: 'ПОДНЕСЕТЕ',
  },
  howToPlay: {
    subline: 'ПРАВИЛА НА',
    headline: 'ИГРАТА',
    previous: 'Previous',
    next: 'СЛЕДНО',
    firstStep: {
      line1: 'ТРЕБА ДА ГИ РЕШИТЕ СИТЕ СЛОЖУВАЛКИ ЕДНА ПО ЕДНА',
      line2: 'КОГА ЌЕ ЈА РЕШИТЕ ПРВАТА СЛОЖУВАЛКА, ПО НЕА АВТОМАТСКИ СЛЕДУВА ВТОРАТА.',
    },
    secondStep: {
      line1: 'ЗЛАТНИОТ ПЕЧАТ ОТВОРЕТЕ ГО ДУРИ ТОГАШ КОГА ТОА ЌЕ БИДЕ ПОБАРАНО ОД ВАС.',
    },
    thirdStep: {
      line1:
        'АКО ЗАГЛАВИТЕ И НЕ ЗНАЕТЕ ШТО ДА ПРАВИТЕ, МОЖЕ ДА ДОБИЕТЕ СОВЕТ КАКО ДА ЈА РЕШИТЕ СЛОЖУВАЛКАТА. ЗА СЕКОЈА СЛОЖУВАЛКА, МОЖЕ ДА ДОБИЕТЕ  ДВА СОВЕТИ',
      line2: 'НО, НЕ ЗАБОРАВАЈТЕ: ПОЗАБАВНО Е БЕЗ ПОМОШ!',
    },
    fourthStep: {
      line1:
        'СЕГА ОТВОРЕТЕ ГО ЗЕЛЕНИОТ ПЛИК И ЗАПОЧНЕТЕ ЈА ПРВАТА СЛОЖУВАЛКА, „ШУМАТА“. ПУШТЕТЕ ЈА МУЗИКАТА ЗА АТМОСФЕРА',
      start: 'ПОЧЕТОК',
    },
    intro: {
      subline: 'ОТКРИЈТЕ ЈА ТАЈНАТА НА',
      headline: 'Jägermeister',
      line1: 'ДОБРЕ ДОЈДОВТЕ ВО ИГРАТА НА БЕГСТВО НА JÄGERMEISTER',
      line2:
        'ЗА МАКСИМАЛНА ЗАБАВА, ВНИМАТЕЛНО ПРОЧИТАЈТЕ ГО УПАТСТВОТО „ПРАВИЛА НА ИГРАТА“ ПРЕД ДА ГО ОТВОРИТЕ ЗЕЛЕНИОТ ПЛИК',
      howToPlay: 'ПРАВИЛА НА ИГРАТА',
    },
  },
  overview: {
    line: 'ДА ЗАПОЧНЕМЕ СО СЛОЖУВАЊЕ!',
    subline: 'ОТКРИЈТЕ ЈА ТАЈНАТА НА',
    headline: 'Jägermeister',
  },
  puzzles: {
    subline: 'Puzzle %s',
    '01': {
      title: 'ШУМАТА',
      headline: 'ИЗБЕРЕТЕ ОДГОВОР ',
      submit: 'ПОДНЕСЕТЕ',
      wrong: {
        headline: 'НЕТОЧНО!',
        texts: {
          0: 'ДАЛИ СЕГА ЈА ГЛЕДАТЕ ЛИСИЦАТА? ЕВЕ ЈА!',
          1: 'ДАЛИ СЕГА ГО ГЛЕДАТЕ ЕЛЕНОТ? ЕВЕ ГО!',
          2: 'ДАЛИ СЕГА ГО ГЛЕДАТЕ БУВОТ? ЕВЕ ГО!',
        },
        tryAgain: 'ОБИДЕТЕ СЕ ПОВТОРНО',
      },
      right: {
        headline: 'ТОЧНО!',
        text: 'ДИВАТА СВИЊА НЕ Е ВО ШУМАТА.',
        next: 'СЛЕДНА ИГРА',
      },
      solved: {
        headline: 'СЕГА ОТСТРАНЕТЕ ГО ПРВИОТ ЗЛАТЕН ПЕЧАТ ЗА ДА ПРЕМИНЕТЕ НА СЛЕДНАТА СЛОЖУВАЛКА.',
        start: 'ЗАПОЧНЕТЕ СО СЛОЖУВАЛКА 2',
      },
      hints: {
        first: 'ПРОНАЈДЕТЕ ГО ЖИВОТНОТО ШТО НЕ СЕ КРИЕ ВО ШУМАТА',
        second:
          'КОРИСТЕТЕ ГО СВОЈОТ ПАМЕТЕН ТЕЛЕФОН И НАПРАВЕТЕ ФОТОГРАФИЈА СО БЛИЦ. НА ФОТОГРАФИЈАТА МОЖЕТЕ ДА ГИ ВИДИТЕ ОЧИТЕ НА ДРУГИТЕ ЖИВОТНИ. ЧИИ ОЧИ НЕ СЕ ГЛЕДААТ?',
      },
    },
    '02': {
      title: 'ОРЛОВО ОКО',
      headline: 'ИЗБЕРЕТЕ КООРДИНАТИ',
      submit: 'ПОДНЕСЕТЕ',
      wrong: {
        headline: 'ЖАЛ НИ Е, ОВА НЕ Е ТОЧНО. ПОГЛЕДНЕТЕ ПОВНИМАТЕЛНО И ОБИДЕТЕ СЕ ПОВТОРНО',
        tryAgain: 'ОБИДЕТЕ СЕ ПОВТОРНО',
      },
      right: {
        headline: 'ДА, ТАКА Е, ОРЛОВО ОКО!',
        next: 'Next Game',
      },
      solved: {
        headline: 'СЕГА ОТСТРАНЕТЕ ГО ВТОРИОТ ЗЛАТЕН ПЕЧАТ ЗА ДА ПРЕМИНЕТЕ НА СЛЕДНАТА СЛОЖУВАЛКА.',
        start: 'ЗАПОЧНЕТЕ СО СЛОЖУВАЛКА 3',
      },
      hints: {
        first: 'НАЈДЕТЕ 3 РАЗЛИКИ НА ГОРНАТА/ДОЛНАТА СЛИКА И ИЗБЕРЕТЕ ГИ КООРДИНАТИТЕ.',
      },
    },
    '03': {
      title: 'Jägermeister Mule',
      headline: 'ИЗБЕРЕТЕ ОДГОВОР',
      submit: 'ПОДНЕСЕТЕ',
      wrong: {
        headline: 'НЕТОЧНО!',
        text: 'ПРОДОЛЖЕТЕ ЗА ДА ГО ПРОНАЈДЕТЕ СОВРШЕНИОТ РЕЦЕПТ',
        tryAgain: 'ОБИДЕТЕ СЕ ПОВТОРНО',
      },
      right: {
        headline: 'ТОЧНО!',
        text: 'ВРЕМЕ Е ЗА JÄGERMEISTER MULE!',
        next: 'СЛЕДНА ИГРА',
      },
      solved: {
        headline: 'СЕГА ОТСТРАНЕТЕ ГО ПРВИОТ ЗЛАТЕН ПЕЧАТ ЗА ДА ПРЕМИНЕТЕ НА СЛЕДНАТА СЛОЖУВАЛКА.',
        start: 'ЗАПОЧНЕТЕ СО СЛОЖУВАЛКА 4',
      },
      hints: {
        first: 'НАЈДЕТЕ ЈА СОСТОЈКАТА ШТО НЕДОСТИГА НА ИНТЕРНЕТ',
        second:
          'ОДЕТЕ НА JAGERMEISTER.COM И ПОБАРАЈТЕ ГО РЕЦЕПТОТ ЗА JÄGERMEISTER MULE. САМО ИЗБЕРЕТЕ ЈА СОСТОЈКАТА ШТО НЕДОСТИГА',
      },
    },
    '04': {
      title: 'ТАЈНИ СОСТОЈКИ',
      headline: 'ИЗБЕРЕТЕ ОДГОВОР',
      submit: 'ПОДНЕСЕТЕ',
      wrong: {
        headline: 'НЕТОЧНО!',
        text: 'ПРОДОЛЖЕТЕ ЗА ДА ЈА ПРОНАЈДЕТЕ ВИСТИНСКАТА СОСТОЈКА',
        tryAgain: 'ОБИДЕТЕ СЕ ПОВТОРНО',
      },
      right: {
        headline: 'ТОЧНО!',
        text: 'РУЗМАРИНОТ НЕ Е СОСТОЈКА НА JÄGERMEISTER',
        next: 'СЛЕДНА ИГРА',
      },
      solved: {
        headline: 'СЕГА ОТСТРАНЕТЕ ГО И ЧЕТВРТИОТ ЗЛАТЕН ПЕЧАТ ЗА ДА ПРЕМИНЕТЕ НА СЛЕДНАТА СЛОЖУВАЛКА',
        start: 'ЗАПОЧНЕТЕ СО СЛОЖУВАЛКА 5',
      },
      hints: {
        first: 'СЛЕДЕТЕ ЈА ЛИНИЈАТА И ПРОНАЈДЕТЕ ЈА СОСТОЈКАТА ШТО НЕ Е ДЕЛ ОД ТАЈНИОТ РЕЦЕПТ НА JÄGERMEISTER',
        second: 'ЦИМЕТ, КАРДАМОН И ЅВЕЗДОВИДЕН АНСОН СЕ СОСТОЈКИТЕ НА JÄGERMEISTER',
      },
    },
    '05': {
      title: 'ОТКРИЈТЕ ЈА ТАЈНАТА',
      headline: 'ВНЕСЕТЕ ОДГОВОР',
      codeHeadline: 'ТАЈНАТА Е',
      submit: 'ПОДНЕСЕТЕ',
      wrong: {
        headline: 'НЕТОЧНО',
        text: 'ОБИДЕТЕ СЕ ПОВТОРНО ЗА ДА ЈА ОТКРИЕТЕ ТАЈНАТА НА JÄGERMEISTER',
        tryAgain: 'ОБИДЕТЕ СЕ ПОВТОРНО',
      },
      hints: {
        first: 'ОТСТРАНЕТЕ ГИ МОНЕТИТЕ И СПОРЕДЕТЕ. ЗАБЕЛЕЖУВАТЕ ЛИ НЕКАКВА РАЗЛИКА?',
        second: 'ПРОНАЈДЕТЕ ГИ БУКВИТЕ ОД ИСТАТА СЛИКА НА МОНЕТАТА И СТАВЕТЕ ГИ НА ВИСТИНСКОТО МЕСТО',
      },
    },
  },
  secret: {
    headline: 'ВИ ЧЕСТИТАМЕ!',
    line1: '**ЈА ОТКРИВТЕ ТАЈНАТА НА JÄGERMEISTER!**',
    line2: 'ВРЕМЕ Е ЗА ЛЕДЕН ШОТ!',
    line3: 'ВИ БЛАГОДАРИМЕ што ја игравте ИГРАТА ЗА БЕГСТВО НА JÄGERMEISTER.',
    button: 'КАКО ДА ГИ КОРИСТИТЕ МОНЕТИТЕ',
  },
  headsOrTails: {
    line1: 'ПИСМО ИЛИ ГЛАВА?',
    line2: 'ЗЛАТНАТА МОНЕТА ЌЕ ОДЛУЧИ КОЈ ЈА ПЛАЌА СЛЕДНАТА ТУРА ЛЕДЕНИ ШОТОВИ ',
    line3: 'ЧУВАЈТЕ ЈА МОНЕТАТА – ЗА НАЈУБАВИТЕ НОЌИ',
    line4: 'НА ЗДРАВЈЕ!',
  },
}
