import * as React from 'react'
import { css, styled } from '../../../../lib/styled-components'
import { Text } from '../../../components/Typography'

interface IProps {
  icon: React.ReactNode
  selected?: boolean
  onClick?: () => any
  large?: boolean
  label?: string
}

export const Choice = ({ icon, selected, onClick, large, label }: IProps) => {
  return <Container {...{ selected, onClick, large }}>
    {icon}
    {label && <Label bold>{label}</Label>}
  </Container>
}

const Container = styled.div<{ selected?: boolean; large?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  border: 1px solid ${({ selected, theme }) => (selected ? theme.inputBorderColor : theme.iconColor)};
  color: ${({ selected, theme }) => (selected ? theme.textColor : theme.iconColor)};
  padding: 16px;
  position: relative;
  svg {
    width: 64px;
    height: 64px;
  }

  ${({ large }) =>
    large &&
    css`
      width: 150px;
      height: 150px;
      svg {
        width: 130px;
        height: 130px;
      }
    `};
`

const Label = styled(Text)`
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  font-size: 11px;
  margin-top: 8px;
`
