const white = '#F1EAD1'
const darkGreen = '#001301'
const orange = '#DD571A'
const gold = '#8E7A49'

export const Theme = {
  white,
  darkGreen,
  orange,
  gold,
  textColor: white,
  highlightTextColor: orange,
  fontFamily: 'Meister-Regular',
  boldFontFamily: 'Meister-Bold',
  inputBorderColor: orange,
  buttonBackgroundColor: darkGreen,
  underlineColor: orange,
  iconColor: gold,
}
