import * as React from 'react'
import { Text } from '../../../../components/Typography'
import { styled } from '../../../../../lib/styled-components'
import { useTranslate } from '../../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../../hooks/useDangerousHTML'

export const FirstHint = () => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  return (
    <Container>
      <Text bold centered {...h(__('puzzles.01.hints.first'))} />
      <Bottom>
        <img src="/images/puzzles/1/hints/1.svg" />
      </Bottom>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Bottom = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
