import * as React from 'react'
import { Text } from '../../components/Typography'
import { PageContainer } from '../../components/PageContainer'
import { styled } from '../../../lib/styled-components'
import { useTranslate } from '../../hooks/useTranslate'
import { useDangerousHTML } from '../../hooks/useDangerousHTML'

export const HeadsOrTailsPage = () => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  return (
    <PageContainer
      backgroundImage={`/images/puzzles/5/background-coin.webp`}
      logoSize="small"
      spreadContent
      withBackButton
    >
      <Top>
        <Text bold centered>
          <p {...h(__('headsOrTails.line1'))} />
          <p {...h(__('headsOrTails.line2'))} />
          <p {...h(__('headsOrTails.line3'))} />
          <p {...h(__('headsOrTails.line4'))} />
        </Text>
      </Top>
      <Bottom>
        <Text as="p" bold centered {...h(__('secret.line3'))} />

        <PoweredByText centered bold>Powered by</PoweredByText>
        <HiddenGamesLogo src="/images/hidden-games.svg" />
      </Bottom>
    </PageContainer>
  )
}

const HiddenGamesLogo = styled.img`
  width: 60px;
  margin: 5px auto 20px auto;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`

const PoweredByText = styled(Text)`
  text-transform: uppercase;
  font-size: 10px;
  margin-bottom: 0;
  margin-top: 10px;
`
