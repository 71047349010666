import * as React from 'react'
import { Text } from '../../../../components/Typography'
import { styled } from '../../../../../lib/styled-components'
import { useTranslate } from '../../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../../hooks/useDangerousHTML'

export const SecondHint = () => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  return (
    <Container>
      <Text bold centered {...h(__('puzzles.03.hints.second'))} />
      <Bottom>
        <Icon src="/images/puzzles/3/hints/2.svg" />
      </Bottom>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Bottom = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 60%;
  margin-top: 2rem;
`
