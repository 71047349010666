import * as React from 'react'
import { LargerText, Text } from '../../../components/Typography'
import { Button } from '../../../components/Forms'
import { styled } from '../../../../lib/styled-components'
import { BoarIcon } from './icons/BoarIcon'
import { Choice } from './Choice'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'

interface IProps {
  onNext: () => any
}

export const RightContent = ({ onNext }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()

  return (
    <Container>
      <Top>
        <LargerText bold centered>
          <p {...h(__('puzzles.01.right.headline'))} />
        </LargerText>
        <Text bold centered>
          <p {...h(__('puzzles.01.right.text'))} />
        </Text>

        <Choice icon={<BoarIcon />} selected large />
      </Top>

      <Button onClick={onNext}>{__('puzzles.01.right.next')}</Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
