export const Locale = {
  name: 'Albanian',
  chooseYourLanguage: 'Choose your language',
  shared: {
    imprint: 'INFORMACIONET E KOMPANISË / PRIVATËSIA ',
    backToMainMenu: 'KTHEHU TE MENYJA KRYESORE',
    backToHowToPlay: 'Back to "how to play"',
  },
  ageCheck: {
    line1:
      'PËR TË HYRË NË KËTË FAQE DUHET TË JESH NË MOSHËN LIGJORE OSE MBI TË, PËR TË KONSUMUAR ALKOOL NË VENDIN NGA KU PO HYN.',
    line2: 'JEP DATËN E LINDJES',
    invalidDate: 'Invalid date',
    minimumAge: 'DUHET TË JESH 18 OSE MË SHUMË PËR QË TË QASEN KËTË FAQE.',
    month: 'MUAJI',
    day: 'DITA',
    year: 'VITI',
    submit: 'DORËZO',
  },
  howToPlay: {
    subline: 'SI TË',
    headline: 'LUASH',
    previous: 'Previous',
    next: 'PËRPARA',
    firstStep: {
      line1: 'DUHET T’I ZGJIDHËSH TË GJITHA ENIGMAT **NJË NGA NJË**',
      line2: 'PASI TË KESH ZGJIDHUR ENIGMËN E PARË, DO TË VIJOJË AUTOMATIKISHT E DYTA.',
    },
    secondStep: {
      line1: 'HAP VULËN E ARTË VETËM KUR TË TË KËRKOHET QË TA BËSH KËTË.',
    },
    thirdStep: {
      line1:
        'NËSE NGEC DHE NUK DI ÇFARË TË BËSH, MUND TË MARRËSH NJË TË DHËNË PËR TË ZGJIDHUR ENIGMËN. PËR SECILËN ENIGMË MUND TË MARRËSH DY TË DHËNA',
      line2: 'POR MOS HARRO: ËSHTË MË ARGËTUESE PA TË DHËNA!',
    },
    fourthStep: {
      line1: 'TANI HAP ZARFIN E GJELBËR DHE FILLO ENIGMËN E PARË “PYLLI”. LUAJ MUZIKËN PËR MË SHUMË ATMOSFERË',
      start: 'FILLO',
    },
    intro: {
      subline: 'ZBULO SEKRETIN E',
      headline: 'JÄGERMEISTER',
      line1: 'MIRË SE VJEN NË LOJËN E ARRATISJES TË JÄGERMEISTER',
      line2:
        'PËR ARGËTIM MAKSIMAL, LEXO ME KUJDES UDHËZIMET E MËPOSHTME “SI TË LUASH” PËRPARA SE TË HAPËSH ZARFIN E GJELBËR',
      howToPlay: 'SI TË LUASH',
    },
  },
  overview: {
    line: 'LE TË FILLOJMË ME ENIGMAT!',
    subline: 'ZBULO SEKRETIN E',
    headline: 'Jägermeister',
  },
  puzzles: {
    subline: 'Puzzle %s',
    '01': {
      title: 'PYLLI',
      headline: 'ZGJIDH PËRGJIGJEN ',
      submit: 'DORËZO',
      wrong: {
        headline: 'E GABUAR!',
        texts: {
          0: 'A E SHIKON DHELPRËN TANI? ËSHTË ATJE!',
          1: 'A E SHIKON DRERIN TANI? ËSHTË ATJE!',
          2: 'A E SHIKON BUFIN TANI? ËSHTË ATJE!',
        },
        tryAgain: 'PROVO PËRSËRI',
      },
      right: {
        headline: 'E SAKTË!',
        text: 'DERRI I EGËR NUK ËSHTË NË PYLL.',
        next: 'LOJA E ARDHSHME',
      },
      solved: {
        headline: 'TANI HIQ VULËN E PARË TË ARTË PËR TË KALUAR NË ENIGMËN E RADHËS.',
        start: 'FILLO ENIGMËN 2',
      },
      hints: {
        first: 'GJEJ KAFSHËN QË NUK ËSHTË E FSHEHUR NË PYLL',
        second:
          'PËRDOR TELEFONIN INTELIGJENT DHE BËJ NJË FOTO ME BLIC. NË FOTO TANI MUND TË SHOHËSH  SYTË E KAFSHËVE TË NDRYSHME. CILAT PALË SY NUK PO GJENDEN?',
      },
    },
    '02': {
      title: 'SYRI I SHQIPONJËS',
      headline: 'ZGJIDH KOORDINATAT',
      submit: 'DORËZO',
      wrong: {
        headline: 'NA VJEN KEQ, ËSHTË E GABUAR. SHIKO PAK MË AFËR DHE PROVO PËRSËRI',
        tryAgain: 'PROVO PËRSËRI',
      },
      right: {
        headline: 'PO, ASHTU ËSHTË, SYRI I SHQIPONJËS!',
        next: 'LOJA E ARDHSHME',
      },
      solved: {
        headline: 'TANI HAP VULËN E DYTË TË ARTË PËR TË KALUAR NË ENIGMËN E RADHËS.',
        start: 'FILLO ENIGMËN 3',
      },
      hints: {
        first: 'GJEJ 3 NDRYSHIMET NË IMAZHIN E SIPËRM / TË POSHTËM DHE ZGJIDH KOORDINATAT.',
      },
    },
    '03': {
      title: 'Jägermeister Mule',
      headline: 'ZGJIDH PËRGJIGJEN',
      submit: 'DORËZO',
      wrong: {
        headline: 'E GABUAR!',
        text: 'VAZHDO QË TË GJESH RECETËN E PËRKRYER',
        tryAgain: 'PROVO PËRSËRI',
      },
      right: {
        headline: 'E SAKTË!',
        text: 'TANI ËSHTË KOHA PËR NJË JÄGERMEISTER MULE!',
        next: 'LOJA E ARDHSHME',
      },
      solved: {
        headline: 'TANI HAP VULËN E DYTË TË ARTË PËR TË KALUAR NË ENIGMËN E RADHËS.',
        start: 'FILLO ENIGMËN 4',
      },
      hints: {
        first: 'GJEJ PËRBËRËSIN QË MUNGON NË INTERNET',
        second: 'SHKO TE **JAGERMEISTER.COM** DHE SHIKO RECETËN PËR JÄGERMEISTER MULE. ZGJIDH PËRBËRËSIN QË MUNGON',
      },
    },
    '04': {
      title: 'PËRBËRËSIT SEKRETË',
      headline: 'ZGJIDH PËRGJIGJEN ',
      submit: 'DORËZO',
      wrong: {
        headline: 'E GABUAR!',
        text: 'VAZHDO QË TË GJESH RECETËN E DUHUR',
        tryAgain: 'PROVO PËRSËRI',
      },
      right: {
        headline: 'E SAKTË!',
        text: 'ROZMARINA NUK ËSHTË PËRBËRËS I JÄGERMEISTER',
        next: 'LOJA E ARDHSHME',
      },
      solved: {
        headline: 'TANI HIQ VULËN E KATËRT TË ARTË PËR TË KALUAR NË ENIGMËN E RADHËS.',
        start: 'FILLO ENIGMËN 5',
      },
      hints: {
        first: 'GJURMO VIJAT DHE GJEJ PËRBËRËSIN QË NUK ËSHTË PJESË E RECETËS SEKRETE TË JÄGERMEISTER',
        second: 'PËRBËRËSIT E JÄGERMEISTER JANË KANELLA, KARDAMOM DHE GLIKANXO TAJLANDEZE',
      },
    },
    '05': {
      title: 'ZBULO SEKRETIN',
      headline: 'JEP PËRGJIGJEN',
      codeHeadline: 'SEKRETI ËSHTË',
      submit: 'DORËZO',
      wrong: {
        headline: 'E GABUAR',
        text: 'PROVO PËRSËRI PËR TË ZBULUAR SEKRETIN E JÄGERMEISTER',
        tryAgain: 'PROVO PËRSËRI',
      },
      hints: {
        first: 'HIQ MONEDHËN DHE KRAHASO. A MUND TË SHIKOSH NDONJË NDRYSHIM?',
        second: 'GJEJ SHKRONJAT ME IMAZHIN IDENTIK TË MONEDHËS DHE VENDOSI ATO NË VENDIN E DUHUR',
      },
    },
  },
  secret: {
    headline: 'URIME!',
    line1: '**KE ZBULUAR SEKRETIN E JÄGERMEISTER!**',
    line2: 'TANI ËSHTË KOHA PËR NJË TEKE TË FTOHTË AKULL!',
    line3: 'FALEMINDERIT QË LUANI LOJËN JÄGERMEISTER ESCAPE.',
    button: 'SI TA PËRDORËSH MONEDHËN',
  },
  headsOrTails: {
    line1: 'KOKË APO PIL?',
    line2: 'MONEDHA E ARTË DO TË VENDOSË SE KUSH PAGUAN PËR RAUNDIN E RADHËS TË TEKEVE TË FTOHTA AKULL ',
    line3: 'MBAJE MONEDHËN ME VETE - PËR NETËT E TUA MË TË MIRA',
    line4: 'GËZUAR!',
  },
}
