import { useEffect, useState } from 'react'
import useAsyncEffect from 'use-async-effect'

const ASSETS = [
  '/images/sound-on.svg',
  '/images/background-reveal.webp',
  '/images/sound-off.svg',
  '/images/background.webp',
  '/images/close.svg',
  '/images/logo-small.webp',
  '/images/logo-large.webp',
  '/images/icons/sound-enabled.svg',
  '/images/icons/sound-disabled.svg',
  '/images/icons/language.svg',
  '/images/puzzles/1/wrong-deer.webp',
  '/images/puzzles/1/wrong-fox.webp',
  '/images/puzzles/1/wrong-owl.webp',
  '/images/puzzles/1/golden-seal.webp',
  '/images/puzzles/1/background.webp',
  '/images/puzzles/1/hints/1.svg',
  '/images/puzzles/1/hints/2.svg',
  '/images/puzzles/4/cocoa.webp',
  '/images/puzzles/4/star-anise.webp',
  '/images/puzzles/4/golden-seal.webp',
  '/images/puzzles/4/rosemary.webp',
  '/images/puzzles/4/background.webp',
  '/images/puzzles/4/pepper.webp',
  '/images/puzzles/4/cinnamon.webp',
  '/images/puzzles/4/hints/1.svg',
  '/images/puzzles/4/hints/2.svg',
  '/images/puzzles/3/lime.webp',
  '/images/puzzles/3/orange-cest-choice.webp',
  '/images/puzzles/3/lime-choice.webp',
  '/images/puzzles/3/golden-seal.webp',
  '/images/puzzles/3/background.webp',
  '/images/puzzles/3/orange-cest.webp',
  '/images/puzzles/3/pepper.webp',
  '/images/puzzles/3/blackberries.webp',
  '/images/puzzles/3/pepper-choice.webp',
  '/images/puzzles/3/hints/1.svg',
  '/images/puzzles/3/hints/2.svg',
  '/images/puzzles/3/blackberries-choice.webp',
  '/images/puzzles/2/wrong.webp',
  '/images/puzzles/2/golden-seal.webp',
  '/images/puzzles/2/background.webp',
  '/images/puzzles/2/right.webp',
  '/images/puzzles/2/hints/1.svg',
  '/images/puzzles/2/hints/2.svg',
  '/images/puzzles/5/wrong.svg',
  '/images/puzzles/5/background.webp',
  '/images/puzzles/5/hints/coin.webp',
  '/images/puzzles/5/hints/2.svg',
  '/images/globe.svg',
  '/images/how-to-play/steps.svg',
  '/images/how-to-play/mail.svg',
  '/images/how-to-play/golden-seal.webp',
  '/images/how-to-play/hint.webp',
  '/images/how-to-play/escape.svg',
  '/images/how-to-play/escape.webp',
  '/images/how-to-play/hint.svg',
  '/images/hint.svg',
]

const loadImage = (asset: string) => {
  return new Promise<void>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => {
      resolve()
    })
    image.addEventListener('error', (e) => {
      resolve()
    })
    image.src = asset
  })
}

export const useAssetPreloader = () => {
  const [loading, setLoading] = useState(true)
  useAsyncEffect(async () => {
    await Promise.all(ASSETS.map((asset) => loadImage(asset)))
    setLoading(false)
  }, [])
  return loading
}
