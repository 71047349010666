import * as React from 'react'
import { styled } from '../../../../lib/styled-components'
import { Choice } from './Choice'
import { FoxIcon } from './icons/FoxIcon'
import { DeerIcon } from './icons/DeerIcon'
import { OwlIcon } from './icons/OwlIcon'
import { BoarIcon } from './icons/BoarIcon'

interface IProps {
  choice: number
  onSelect: (choice: number) => any
}

export const Choices = ({ choice, onSelect }: IProps) => {
  return (
    <Container>
      <Choice icon={<FoxIcon />} onClick={onSelect.bind(null, 0)} selected={choice === 0} />
      <Choice icon={<DeerIcon />} onClick={onSelect.bind(null, 1)} selected={choice === 1} />
      <Choice icon={<OwlIcon />} onClick={onSelect.bind(null, 2)} selected={choice === 2} />
      <Choice icon={<BoarIcon />} onClick={onSelect.bind(null, 3)} selected={choice === 3} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 95px 95px;
  grid-row: auto;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin: 0 auto;
  height: 205px;
`
