import * as React from 'react'
import { LargerText, Text } from '../../../components/Typography'
import { styled } from '../../../../lib/styled-components'
import { Button } from '../../../components/Forms'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'

interface IProps {
  onTryAgain?: () => any
}

export const WrongContent = ({ onTryAgain }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()

  return (
    <Container>
      <Top>
        <LargerText bold centered>
          <p>
            <strong {...h(__('puzzles.05.wrong.headline'))} />
          </p>
        </LargerText>
        <Text bold centered>
          <p {...h(__('puzzles.05.wrong.text'))} />
        </Text>

        <ImageContainer>
          <Image src="/images/puzzles/5/wrong.svg" />
        </ImageContainer>
      </Top>

      <Button onClick={onTryAgain}>{__('puzzles.05.wrong.tryAgain')}</Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  padding: 16px;
  width: 70%;
  height: 160px;
`
