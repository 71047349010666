import * as React from 'react'
import { useCallback } from 'react'
import { PageContainer } from '../../components/PageContainer'
import { useParams } from 'react-router-dom'
import { FirstStepContent } from './steps/FirstStepContent'
import { SecondStepContent } from './steps/SecondStepContent'
import { ThirdStepContent } from './steps/ThirdStepContent'
import { FourthStepContent } from './steps/FourthStepContent'
import { Headline } from '../../components/Headline'
import { useTranslate } from '../../hooks/useTranslate'

export const HowToPlayPage = () => {
  const { step = '1' } = useParams<{ step?: string }>()
  const __ = useTranslate()

  const renderStepContent = useCallback(() => {
    switch (step) {
      case '1':
        return <FirstStepContent />
      case '2':
        return <SecondStepContent />
      case '3':
        return <ThirdStepContent />
      case '4':
        return <FourthStepContent />
    }
  }, [step])

  return (
    <PageContainer backgroundImage="/images/background.webp" logoSize="small" spreadContent logoLink={null}>
      <Headline smallText={__('howToPlay.subline')} largeText={__('howToPlay.headline')} />

      {renderStepContent()}
    </PageContainer>
  )
}
