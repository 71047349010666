import * as React from 'react'
import { css, styled } from '../../lib/styled-components'
import { Logo } from './Logo'
import { Link } from 'react-router-dom'
import { useTranslate } from '../hooks/useTranslate'
import { LanguageSelector } from './LanguageSelector'
import { SoundToggle } from './SoundToggle'

interface IProps {
  backgroundImage?: string
  children?: React.ReactNode
  logoSize?: 'large' | 'small'
  spreadContent?: boolean
  withBackButton?: boolean
  withBackToHowToPlayButton?: boolean
  logoLink?: string
  className?: string
  scrollable?: boolean
}

export const PageContainer = ({
  backgroundImage,
  children,
  spreadContent,
  withBackButton,
  withBackToHowToPlayButton,
  logoSize = 'small',
  logoLink = '/quiz',
  className,
  scrollable
}: IProps) => {
  const __ = useTranslate()
  return (
    <Container style={{ backgroundImage: backgroundImage && `url(${backgroundImage})` }} {...{ className, scrollable }}>
      {logoLink ? (
        <LogoLink to={logoLink}>
          <Logo size={logoSize} />
        </LogoLink>
      ) : (
        <Logo size={logoSize} />
      )}
      <Content spread={spreadContent}>{children}</Content>
      <Bottom>
        {withBackButton ? (
          <FooterLink to="/quiz">{__('shared.backToMainMenu')}</FooterLink>
        ) : (
          <FooterLink to="/imprint">{__('shared.imprint')}</FooterLink>
        )}
        {withBackToHowToPlayButton && <FooterLink to="/how-to-play">{__('shared.backToHowToPlay')}</FooterLink>}
      </Bottom>
      <LanguageSelector />
      <SoundToggle />
    </Container>
  )
}

const Container = styled.div<{ scrollable?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  padding: 55px 20px 20px 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.textColor};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  p {
    margin: 0.5em 0;
  }
  ${({ scrollable }) => scrollable && `overflow-y: auto;`};
`

const Content = styled.div<{ spread?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${({ spread }) =>
    spread &&
    css`
      justify-content: space-between;
    `};
`

const Bottom = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const FooterLink = styled(Link)`
  font-family: ${({ theme }) => theme.boldFontFamily};
  color: ${({ theme }) => theme.textColor};
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 10px;
  letter-spacing: 1px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`
