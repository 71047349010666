export const Locale = {
  name: 'Hungarian',
  chooseYourLanguage: 'Choose your language',
  shared: {
    imprint: 'IMPRESSZUM/ADATVÉDELEM',
    backToMainMenu: 'VISSZA A FŐMENÜBE',
    backToHowToPlay: 'Back to "how to play"',
  },
  ageCheck: {
    line1: 'EZT AZ OLDALT CSAK A 18. ÉLETÉVÜKET BETÖLTÖTT SZEMÉLYEK LÁTOGATHATJÁK.',
    line2: 'KÉRLEK, ADD MEG A SZÜLETÉSI DÁTUMOD:',
    invalidDate: 'Invalid date',
    minimumAge: 'AZ OLDAL ELÉRÉSÉHEZ LEGALÁBB 18 ÉVESNEK KELL LENNED.',
    month: 'HÓNAP',
    day: 'NAP',
    year: 'ÉV',
    submit: 'KÜLDÉS',
  },
  howToPlay: {
    subline: '',
    headline: 'JÁTÉKSZABÁLYOK',
    previous: 'Previous',
    next: 'KÖVETKEZŐ',
    firstStep: {
      line1: 'EGYENKÉNT KELL MEGOLDANOD AZ ÖSSZES REJTVÉNYT',
      line2: 'AZ ELSŐ REJTVÉNY MEGOLDÁSA UTÁN AUTOMATIKUSAN JÖNNI FOG A MÁSODIK.',
    },
    secondStep: {
      line1: 'CSAK AKKOR TÖRD FEL AZ ARANYPECSÉTET, HA ARRA UTASÍTÁST KAPSZ!',
    },
    thirdStep: {
      line1:
        'HA ELAKADSZ ÉS NEM TUDOD, MIT KELL TENNED, KÉRHETSZ TIPPET A REJTVÉNY MEGOLDÁSÁHOZ. MINDEN REJTVÉNYNÉL KÉT TIPPET KÉRHETSZ',
      line2: 'DE NE FELEDD: TIPPEK NÉLKÜL IZGALMASABB A JÁTÉK!',
    },
    fourthStep: {
      line1:
        'MOST NYISD KI A ZÖLD BORÍTÉKOT, ÉS KEZDD AZ ELSŐ REJTVÉNNYEL, MELYNEK TÉMÁJA „AZ ERDŐ”. A HANGULAT FOKOZÁSÁHOZ KAPCSOLD BE A ZENÉT!',
      start: 'INDÍTÁS',
    },
    intro: {
      subline: 'FEDD FEL A',
      headline: 'JÄGERMEISTER TITKÁT',
      line1: 'ÜDVÖZLÜNK A **JÄGERMEISTER ESCAPE JÁTÉKBAN!**',
      line2:
        'A MAXIMÁLIS SZÓRAKOZÁS ÉRDEKÉBEN, KÉRJÜK, HOGY A ZÖLD BORÍTÉK FELBONTÁSA ELŐTT FIGYELMESEN OLVASD EL AZ ALÁBBI „HOGYAN KELL JÁTSZANI” UTASÍTÁSOKAT.',
      howToPlay: 'JÁTÉKSZABÁLYOK',
    },
  },
  overview: {
    line: 'KEZDJÜNK IS NEKI A REJTVÉNYEKNEK!',
    subline: 'FEDD FEL A',
    headline: 'JÄGERMEISTER TITKÁT',
  },
  puzzles: {
    subline: 'Puzzle %s',
    '01': {
      title: 'AZ ERDŐ',
      headline: 'VÁLASZD KI A VÁLASZOD ',
      submit: 'KÜLDÉS',
      wrong: {
        headline: 'EZ NEM JÓ VÁLASZ!',
        texts: {
          0: 'MOST LÁTOD A RÓKÁT? OTT VAN!',
          1: 'MOST LÁTOD A SZARVAST? OTT VAN!',
          2: 'MOST LÁTOD A BAGLYOT? OTT VAN!',
        },
        tryAgain: 'PRÓBÁLD ÚJRA!',
      },
      right: {
        headline: 'HELYES!',
        text: 'A VADDISZNÓ NINCS AZ ERDŐBEN.',
        next: 'KÖVETKEZŐ JÁTÉK',
      },
      solved: {
        headline: 'MOST TÖRD FEL AZ ELSŐ ARANYPECSÉTET, HOGY TOVÁBBLÉPHESS A KÖVETKEZŐ REJTVÉNYHEZ!',
        start: '2. REJTVÉNY INDÍTÁSA',
      },
      hints: {
        first: 'TALÁLD MEG AZT AZ ÁLLATOT, AMELY NEM REJTŐZKÖDIK AZ ERDŐBEN!',
        second:
          'HASZNÁLD AZ OKOSTELEFONOD, ÉS KÉSZÍTS FÉNYKÉPET VAKUVAL! A KÉPEN MOST MÁR LÁTHATOD A KÜLÖNBÖZŐ ÁLLATSZEMEKET. MELYIK SZEMPÁR NEM TALÁLHATÓ MEG?',
      },
    },
    '02': {
      title: 'SASSZEM',
      headline: 'VÁLASZD KI A KOORDINÁTÁKAT',
      submit: 'KÜLDÉS',
      wrong: {
        headline: 'SAJNÁLJUK, EZ NEM JÓ VÁLASZ. NÉZD MEG EGY KICSIT KÖZELEBBRŐL ÉS PRÓBÁLD MEG ÚJRA!',
        tryAgain: 'PRÓBÁLD ÚJRA!',
      },
      right: {
        headline: 'IGEN, PONTOSAN, SASSZEMED VAN!',
        next: 'KÖVETKEZŐ JÁTÉK',
      },
      solved: {
        headline: 'MOST TÖRD FEL A MÁSODIK ARANYPECSÉTET, HOGY TOVÁBBLÉPHESS A KÖVETKEZŐ REJTVÉNYHEZ!',
        start: '3. REJTVÉNY INDÍTÁSA',
      },
      hints: {
        first: 'KERESD MEG A FELSŐ ÉS AZ ALSÓ KÉP KÖZÖTTI 3 KÜLÖNBSÉGET, ÉS VÁLASZD KI A KOORDINÁTÁKAT!',
      },
    },
    '03': {
      title: 'Jägermeister Mule',
      headline: 'VÁLASZD KI A VÁLASZOD',
      submit: 'KÜLDÉS',
      wrong: {
        headline: 'EZ NEM JÓ VÁLASZ!',
        text: 'FOLYTASD A KERESÉST, ÉS TALÁLD MEG A TÖKÉLETES RECEPTET!',
        tryAgain: 'PRÓBÁLD ÚJRA!',
      },
      right: {
        headline: 'HELYES!',
        text: 'ITT AZ IDEJE INNI EGY JÄGERMEISTER MULE-T!',
        next: 'KÖVETKEZŐ JÁTÉK',
      },
      solved: {
        headline: 'MOST TÖRD FEL A HARMADIK ARANYPECSÉTET, HOGY TOVÁBBLÉPHESS A KÖVETKEZŐ REJTVÉNYHEZ!',
        start: '4. REJTVÉNY INDÍTÁSA',
      },
      hints: {
        first: 'KERESD MEG A HIÁNYZÓ ÖSSZETEVŐT AZ INTERNETEN!',
        second:
          'LÁTOGASS EL A **JAGERMEISTER.COM** OLDALRA, ÉS KERESD MEG A JÄGERMEISTER MULE RECEPTJÉT. CSAK VÁLASZD KI A HIÁNYZÓ ÖSSZETEVŐT!',
      },
    },
    '04': {
      title: 'TITKOS ÖSSZETEVŐK',
      headline: 'VÁLASZD KI A VÁLASZOD',
      submit: 'KÜLDÉS',
      wrong: {
        headline: 'EZ NEM JÓ VÁLASZ!',
        text: 'FOLYTASD A KERESÉST, HOGY MEGTALÁLD A MEGFELELŐ ÖSSZETEVŐT!',
        tryAgain: 'PRÓBÁLD ÚJRA!',
      },
      right: {
        headline: 'HELYES!',
        text: 'A ROZMARING NEM A JÄGERMEISTER ÖSSZETEVŐJE',
        next: 'KÖVETKEZŐ JÁTÉK',
      },
      solved: {
        headline: 'MOST TÖRD FEL A NEGYEDIK ARANYPECSÉTET, HOGY TOVÁBBLÉPHESS A KÖVETKEZŐ REJTVÉNYHEZ!',
        start: '5. REJTVÉNY INDÍTÁSA',
      },
      hints: {
        first: 'KÖVESD A VONALAKAT, ÉS TALÁLD MEG AZT AZ ÖSSZETEVŐT, AMELY NEM RÉSZE A TITKOS JÄGERMEISTER-RECEPTNEK!',
        second: 'A FAHÉJ, A KARDAMOM ÉS A CSILLAGÁNIZS A JÄGERMEISTER ÖSSZETEVŐJE',
      },
    },
    '05': {
      title: 'FEDD FEL A TITKOT!',
      headline: 'ADD MEG A VÁLASZOD!',
      codeHeadline: 'A TITOK PEDIG…',
      submit: 'KÜLDÉS',
      wrong: {
        headline: 'EZ NEM JÓ VÁLASZ',
        text: 'PRÓBÁLD MEG ÚJRA FELFEDNI A JÄGERMEISTER TITKÁT!',
        tryAgain: 'PRÓBÁLD ÚJRA!',
      },
      hints: {
        first: 'TÁVOLÍTSD EL AZ ÉRMÉT, ÉS HASONLÍTSD ÖSSZE! LÁTSZ KÜLÖNBSÉGET?',
        second: 'KERESD MEG AZ AZONOS ÉRMÉKKEL ELLÁTOTT BETŰKET, ÉS TEDD ŐKET A MEGFELELŐ HELYRE!',
      },
    },
  },
  secret: {
    headline: 'GRATULÁLUNK!',
    line1: '**FELFEDTED A JÄGERMEISTER TITKÁT!**',
    line2: 'ITT AZ IDEJE INNI EGY JÉGHIDEG SHOTOT!',
    line3: 'KÖSZÖNJÜK, HOGY JÁTSZOTTÁL A JÄGERMEISTER SZABADULÓS JÁTÉKKAL.',
    button: 'AZ ÉRME HASZNÁLATA',
  },
  headsOrTails: {
    line1: 'FEJ VAGY ÍRÁS?',
    line2: 'AZ ARANYÉRME ELDÖNTI MAJD, HOGY KI FIZETI A KÖVETKEZŐ KÖR JÉGHIDEG SHOTOT ',
    line3: 'TARTSD MAGADNÁL AZ ÉRMÉT – A LEGJOBB ÉJSZAKÁKÉRT',
    line4: 'EGÉSZSÉGEDRE!',
  },
}
