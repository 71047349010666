import * as React from 'react'
import { Text } from '../../../components/Typography'
import { Button } from '../../../components/Forms'
import { styled } from '../../../../lib/styled-components'
import { Choice } from '../01-the-forest/Choice'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'

interface IProps {
  onNext: () => any
}

export const SolvedContent = ({ onNext }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()

  return (
    <Container>
      <Top>
        <Text bold centered>
          <p {...h(__('puzzles.03.solved.headline'))} />
        </Text>

        <Choice icon={<GoldenSealIcon src="/images/puzzles/3/golden-seal.webp" />} selected large />
      </Top>

      <Button onClick={onNext}>{__('puzzles.03.solved.start')}</Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GoldenSealIcon = styled.img`
  width: 130px;
  height: 130px;
`
