import * as React from 'react'
import { css, styled } from '../../../../lib/styled-components'

interface IProps {
  icon: React.ReactNode
  selected?: boolean
  onClick?: () => any
  large?: boolean
}

export const Choice = ({ icon, selected, onClick, large }: IProps) => {
  return <Container {...{ selected, onClick, large }}>{icon}</Container>
}

const Container = styled.div<{ selected?: boolean; large?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  border: 1px solid ${({ selected, theme }) => (selected ? theme.inputBorderColor : theme.iconColor)};
  color: ${({ selected, theme }) => (selected ? theme.textColor : theme.iconColor)};
  padding: 16px;
  transition: all 0.3s ease-out;
  svg {
    width: 64px;
    height: 64px;
  }

  ${({ large }) =>
    large &&
    css`
      width: 150px;
      height: 150px;
      svg {
        width: 130px;
        height: 130px;
      }
    `};
`
