import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { styled } from '../../lib/styled-components'
import { useSound } from '../hooks/useSound'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export const SoundToggle = () => {
  const { sound, setSound } = useSound()

  const handleClick = useCallback(() => {
    setSound(!sound)
  }, [sound])

  return (
    <TransitionGroup>
      <CSSTransition key={sound ? 'on' : 'off'} classNames="fade" timeout={200}>
        {sound ? (
          <Icon src="/images/sound-on.svg" onClick={handleClick} />
        ) : (
          <Icon src="/images/sound-off.svg" onClick={handleClick} />
        )}
      </CSSTransition>
    </TransitionGroup>
  )
}

const Icon = styled.img`
  width: 30px;
  height: 30px;
  position: fixed;
  top: 20px;
  left: 25px;
`
