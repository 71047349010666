import { styled } from '../../lib/styled-components'

export const OuterPuzzleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .fade-exit {
    opacity: 0;
  }
  &,
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.fade-exit {
      opacity: 0;
    }
  }
  > div:not(:empty) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
`
