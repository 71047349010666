import * as React from 'react'
import { useCallback, useState } from 'react'
import { styled } from '../../lib/styled-components'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

interface IProps {
  hintContents: React.ReactNode[]
  onShow?: () => any
  onHide?: () => any
}

export const Hints = ({ hintContents, onShow, onHide }: IProps) => {
  const [expanded, setExpanded] = useState(false)
  const [selectedHintIndex, setSelectedHintIndex] = useState(null)
  const [firstHintSelected, setFirstHintSelected] = useState(false)
  const handleIconClick = useCallback(() => {
    setExpanded(true)
  }, [])
  const handleHintClick = useCallback((index: number) => {
    setFirstHintSelected(true)
    setSelectedHintIndex(index)
    onShow?.()
  }, [])
  const handleClickOutside = useCallback(() => {
    setSelectedHintIndex(null)
    setExpanded(false)
    onHide?.()
  }, [])

  return (
    <>
      <Container>
        <TransitionGroup>
          <CSSTransition key={expanded ? 'on' : 'off'} classNames="fade" timeout={300}>
            {expanded ? (
              <HintList>
                <TransitionGroup>
                  {hintContents.slice(0, firstHintSelected ? 2 : 1).map((hintContent, index) => (
                    <CSSTransition key={index} classNames="fade" timeout={300}>
                      <HintItem onClick={handleHintClick.bind(null, index)} faded={selectedHintIndex === index}>{`0${
                        index + 1
                      }`}</HintItem>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </HintList>
            ) :  <HintIcon src="/images/hint.svg" onClick={handleIconClick} />}
          </CSSTransition>
        </TransitionGroup>
      </Container>
      <TransitionGroup>
        {hintContents.map((hintContent, index) =>
          selectedHintIndex === index &&
          (<CSSTransition key={index} classNames="fade" timeout={300}>
            <OverlayContainer onClick={handleClickOutside}>
              <Overlay>{hintContents[index]}</Overlay>
            </OverlayContainer>
          </CSSTransition>)
        )}
      </TransitionGroup>
    </>
  )
}

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 30px;
`

const HintIcon = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 40px;
  left: 17px;
`

const HintList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 3;
`

const HintItem = styled.li<{ faded?: boolean }>`
  width: 80px;
  height: 60px;
  margin-bottom: 5px;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.boldFontFamily};
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  transition: all 0.3s ease-out;
  ${({ faded }) => faded && `opacity: 0.5`};
`

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  &.fade-exit {
    opacity: 0;
    z-index: 1;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  padding: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
`
