export const Locale = {
  name: 'English',
  chooseYourLanguage: 'Choose your language',
  shared: {
    imprint: 'Imprint / Privacy',
    backToMainMenu: 'Back to main menu',
    backToHowToPlay: 'Back to "how to play"',
  },
  ageCheck: {
    line1: 'To access this page you must be of legal drinking age in your country of access or older.',
    line2: 'Please enter your date of birth:',
    invalidDate: 'Invalid date',
    minimumAge: 'You must be 18 or older to access this page.',
    month: 'Month',
    day: 'Day',
    year: 'Year',
    submit: 'Submit',
  },
  howToPlay: {
    subline: 'how to',
    headline: 'play',
    previous: 'Previous',
    next: 'Next',
    firstStep: {
      line1: 'You are going to solve all puzzles **step by step**.',
      line2: 'Once you have solved the first puzzle, the second will follow automatically.',
    },
    secondStep: {
      line1: 'OPEN THE **GOLDEN SEAL** ONLY WHEN ASKED TO DO SO.',
    },
    thirdStep: {
      line1:
        'IF YOU GET STUCK AND DON‘T KNOW WHAT TO DO, YOU CAN GET A HINT TO SOLVE THE PUZZLE. **FOR EACH PUZZLE YOU CAN GET 2 HINTS.**',
      line2: 'BUT DON‘T FORGET: IT‘S MORE FUN WITHOUT ANY HINTS!',
    },
    fourthStep: {
      line1:
        'OPEN NOW THE GREEN ENVELOPE AND START WITH THE FIRST PUZZLE **„THE FOREST“**. PLAY THE MUSIC FOR MORE ATMOSPHERE.',
      start: 'Start',
    },
    intro: {
      subline: 'Unveil the secret of',
      headline: 'Jägermeister',
      line1: 'Welcome to the **Jägermeister Escape Game.**',
      line2:
        'For maximum fun please read the following **„How to play“** instructions carefully before you open the **green** envelope.',
      howToPlay: 'How to play',
    },
  },
  overview: {
    line: "Let's get started with the puzzles!",
    subline: 'Unveil the secret of',
    headline: 'Jägermeister',
  },
  puzzles: {
    subline: 'Puzzle %s',
    '01': {
      title: 'The Forest',
      headline: 'Choose your answer',
      submit: 'submit',
      wrong: {
        headline: "That's wrong!",
        texts: {
          0: "Do you see the fox now? It's right there!",
          1: "Do you see the deer now? It's right there!",
          2: "Do you see the owl now? It's right there!",
        },
        tryAgain: 'Try again',
      },
      right: {
        headline: "That's right!",
        text: 'The boar is not in the forest.',
        next: 'Next game',
      },
      solved: {
        headline: 'Now remove the first golden seal to move on to the next puzzle.',
        start: 'Start Puzzle 02',
      },
      hints: {
        first: 'FIND THE ANIMAL THAT IS NOT HIDING IN THE FOREST.',
        second:
          'USE YOUR SMARTPHONE AND TAKE A PHOTO WITH A FLASH. IN THE PHOTO YOU CAN NOW SEE THE DIFFERENT ANIMAL EYES. WHICH PAIR OF EYES IS NOT TO BE FOUND?',
      },
    },
    '02': {
      title: 'Eagle-Eye',
      headline: 'Select the coordinates',
      submit: 'Submit',
      wrong: {
        headline: 'SORRY, THAT’S WRONG, LOOK A LITTLE CLOSER AND TRY AGAIN.',
        tryAgain: 'Try again',
      },
      right: {
        headline: "Yes, that's right, eagle-eye!",
        next: 'Next Game',
      },
      solved: {
        headline: 'Now remove the second golden seal to move on to the next puzzle.',
        start: 'Start Puzzle 03',
      },
      hints: {
        first: 'FIND THE 3 DIFFERENCES IN THE UPPER/LOWER IMAGE AND SELECT THE COORDINATES.',
      },
    },
    '03': {
      title: 'Jägermeister Mule',
      headline: 'Choose your answer',
      submit: 'Submit',
      wrong: {
        headline: "That's wrong!",
        text: 'Keep going to find the perfect recipe.',
        tryAgain: 'Try again',
      },
      right: {
        headline: "That's right!",
        text: "Now it's time for a Jägermeister Mule!",
        next: 'Next Game',
      },
      solved: {
        headline: 'Now remove the third golden seal to move on to the next puzzle.',
        start: 'Start Puzzle 04',
      },
      hints: {
        first: 'FIND THE MISSING INGREDIENT ON THE INTERNET',
        second:
          'GO TO **JAGERMEISTER.COM** AND LOOK AT THE RECIPE FOR JÄGERMEISTER MULE. JUST PICK THE MISSING INGREDIENT.',
      },
    },
    '04': {
      title: 'Secret Ingredients',
      headline: 'Choose your answer',
      submit: 'Submit',
      wrong: {
        headline: "That's wrong!",
        text: 'Keep going to find the right ingredient.',
        tryAgain: 'Try again',
      },
      right: {
        headline: "That's right!",
        text: 'Rosemary is not an ingredient of Jägermeister.',
        next: 'Next game',
      },
      solved: {
        headline: 'Now remove the fourth golden seal to move on to the next puzzle.',
        start: 'Start Puzzle 05',
      },
      hints: {
        first: 'TRACE THE LINES AND FIND THE INGREDIENT THAT IS NOT PART OF THE SECRET JÄGERMEISTER RECIPE.',
        second: 'CINNAMON, CARDAMOM AND STAR ANISE ARE INGREDIENTS OF JÄGERMEISTER.',
      },
    },
    '05': {
      title: 'Unveil the secret',
      headline: 'Enter your answer',
      codeHeadline: 'The secret is',
      submit: 'Submit',
      wrong: {
        headline: "That's wrong",
        text: 'Try again to unveil the secret of Jägermeister.',
        tryAgain: 'Try again',
      },
      hints: {
        first: 'Remove the coin and compare. Can you see any differences?',
        second: 'Find the letters with the identical coin image and put them in the right place.',
      },
    },
  },
  secret: {
    headline: 'Congratulations!',
    line1: '**You have revealed the secret of Jägermeister!**',
    line2: "Now it's time for an ice cold shot!",
    line3: 'Thank you for playing the Jägermeister Escape Game.',
    button: 'How to use the coin',
  },
  headsOrTails: {
    line1: 'Heads or tails?',
    line2: 'The **Jägermeister coin** will decide who pays for the next round of ice cold shots.',
    line3: 'Keep the **Jägermeister coin** with you for your best nights!',
    line4: 'Prost!',
  },
}
