import * as React from 'react'
import { Text, LargerText } from '../../components/Typography'
import { PageContainer } from '../../components/PageContainer'
import { Headline } from '../../components/Headline'
import { Button } from '../../components/Forms'
import { styled } from '../../../lib/styled-components'
import { Link } from 'react-router-dom'
import { useTranslate } from '../../hooks/useTranslate'
import { useDangerousHTML } from '../../hooks/useDangerousHTML'

export const IntroPage = () => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  return (
    <PageContainer backgroundImage="/images/background.webp" logoSize="small" spreadContent logoLink={null}>
      <div>
        <Headline smallText={__('howToPlay.intro.subline')} largeText={__('howToPlay.intro.headline')} />

        <Text bold centered>
          <p {...h(__('howToPlay.intro.line1'))} />
          <p {...h(__('howToPlay.intro.line2'))} />
        </Text>
      </div>

      <Icon src="/images/how-to-play/mail.svg" />

      <Button as={Link} to="/how-to-play">
        {__('howToPlay.intro.howToPlay')}
      </Button>
    </PageContainer>
  )
}

const Icon = styled.img`
  max-width: 200px;
  margin-right: -26px;
  margin-bottom: 20px;
  flex: 1;
`
