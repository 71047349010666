import { css, styled } from '../../lib/styled-components'

export const Text = styled.span<{ centered?: boolean; bold?: boolean }>`
  font-family: ${({ bold, theme }) => (bold ? theme.boldFontFamily : theme.fontFamily)};
  text-transform: uppercase;
  ${({ centered }) => centered && `text-align: center`};
  letter-spacing: 1.1px;
  font-size: 14px;
  line-height: 18px;

  strong {
    color: ${({ theme }) => theme.highlightTextColor};
  }
`

export const LargerText = styled(Text)`
  font-size: 21px;
  line-height: 27px;
`

export const Heading = styled.div<{ small?: boolean; large?: boolean; noUnderline?: boolean }>`
  font-family: ${({ theme }) => theme.boldFontFamily};
  text-transform: uppercase;
  ${({ noUnderline }) =>
    !noUnderline &&
    css`
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.underlineColor};
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
    `};
  text-align: center;
  padding-bottom: 2px;
  ${({ small }) =>
    small &&
    css`
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 10px;
      line-height: 20px;
    `};
  ${({ large }) =>
    large &&
    css`
      font-size: 32px;
      letter-spacing: 1.2px;
      line-height: 32px;
    `};
`
