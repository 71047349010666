import { PuzzleData } from '../../PuzzleData'

const LS_AGE_KEY = 'age'
const LS_CODE_KEY = 'code'
const LS_SECRET_KEY = 'secret'
const LS_LANGUAGE_KEY = 'language'

export class ApplicationStore {
  private _age: number
  private _latestPuzzleCode: string
  private _finishedQuiz: boolean = false
  private _language: string = 'en'

  public onLanguageUpdate?: () => any

  constructor() {
    const age = localStorage.getItem(LS_AGE_KEY)
    if (age) {
      this._age = parseInt(age, 10)
    }
    const code = localStorage.getItem(LS_CODE_KEY)
    if (code) {
      this._latestPuzzleCode = code
    }
    const secret = localStorage.getItem(LS_SECRET_KEY)
    if (secret === 'The secret is ice cold.') {
      this._finishedQuiz = true
    }
    const language = localStorage.getItem(LS_LANGUAGE_KEY)
    if (language) {
      this._language = language
    }
  }

  get age(): number {
    return this._age
  }

  set age(value: number) {
    this._age = value
    localStorage.setItem(LS_AGE_KEY, value.toString())
  }

  nextPuzzle() {
    const currentCodeIndex = PuzzleData.indexOf(PuzzleData.find((p) => p.code === this.latestPuzzleCode))
    this.latestPuzzleCode = PuzzleData[currentCodeIndex + 1]?.code
    return this.latestPuzzleCode
  }

  isOfDrinkingAge(): boolean {
    return this._age >= 16
  }

  finishQuiz() {
    this._finishedQuiz = true
    localStorage.setItem(LS_SECRET_KEY, 'The secret is ice cold.')
  }

  canSeePuzzleWithCode(code: string): boolean {
    const currentCodeIndex = PuzzleData.indexOf(PuzzleData.find((p) => p.code === this.latestPuzzleCode))
    const codeIndex = PuzzleData.indexOf(PuzzleData.find((p) => p.code === code))
    if (currentCodeIndex === -1) {
      return false
    }
    return codeIndex <= currentCodeIndex
  }

  get latestPuzzleCode(): string {
    return this._latestPuzzleCode || PuzzleData[0].code
  }

  set latestPuzzleCode(value: string) {
    this._latestPuzzleCode = value
    localStorage.setItem(LS_CODE_KEY, value.toString())
  }

  get finishedQuiz(): boolean {
    return this._finishedQuiz
  }

  get language() {
    return this._language
  }

  set language(language: string) {
    this._language = language
    localStorage.setItem(LS_LANGUAGE_KEY, language)
    this.onLanguageUpdate?.()
  }
}
