import * as React from 'react'
import { styled } from '../../../../lib/styled-components'
import { Choice } from './Choice'

interface IProps {
  choice: number
  onSelect: (choice: number) => any
}

export const Choices = ({ choice, onSelect }: IProps) => {
  return (
    <Container>
      <Choice
        icon={<ChoiceImage src="/images/puzzles/3/blackberries-choice.webp" />}
        onClick={onSelect.bind(null, 0)}
        selected={choice === 0}
      />
      <Choice
        icon={<ChoiceImage src="/images/puzzles/3/orange-cest-choice.webp" />}
        onClick={onSelect.bind(null, 1)}
        selected={choice === 1}
      />
      <Choice
        icon={<ChoiceImage src="/images/puzzles/3/pepper-choice.webp" />}
        onClick={onSelect.bind(null, 2)}
        selected={choice === 2}
      />
      <Choice
        icon={<ChoiceImage src="/images/puzzles/3/lime-choice.webp" />}
        onClick={onSelect.bind(null, 3)}
        selected={choice === 3}
      />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 125px 125px;
  grid-row: auto;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin: 0 auto;
`

const ChoiceImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
