import * as React from 'react'
import { useCallback, useState } from 'react'
import { TPuzzleState } from '../../../shared/Types'
import { IngameContent } from './IngameContent'
import { WrongContent } from './WrongContent'
import { RightContent } from './RightContent'
import { SolvedContent } from './SolvedContent'
import { useQuizSolved } from '../../../hooks/useQuizSolved'
import { OuterPuzzleContainer } from '../../../components/OuterPuzzleContainer'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export const SpotTheDifferencePuzzle = () => {
  const [state, setState] = useState<TPuzzleState>('ingame')

  const handleTryAgain = useCallback(() => {
    setState('ingame')
  }, [])

  const handleSolved = useQuizSolved()

  const handleResult = useCallback((result: [string, number][]) => {
    const correctResult = [
      ['B', 2],
      ['C', 2],
      ['B', 3],
    ]
    const resultIsCorrect =
      result.length === correctResult.length &&
      result.find(([r, c]) => r === correctResult[0][0] && c === correctResult[0][1]) &&
      result.find(([r, c]) => r === correctResult[1][0] && c === correctResult[1][1]) &&
      result.find(([r, c]) => r === correctResult[2][0] && c === correctResult[2][1])

    if (resultIsCorrect) {
      setState('right')
    } else {
      setState('wrong')
    }
  }, [])

  return (
    <OuterPuzzleContainer>
      <TransitionGroup>
        {state === 'ingame' && (
          <CSSTransition key="ingame" classNames="fade" timeout={300}>
            <IngameContent onSubmit={handleResult} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {state === 'wrong' && (
          <CSSTransition key="wrong" classNames="fade" timeout={300}>
            <WrongContent onTryAgain={handleTryAgain} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {state === 'right' && (
          <CSSTransition key="right" classNames="fade" timeout={300}>
            <RightContent onNext={() => setState('solved')} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {state === 'solved' && (
          <CSSTransition key="solved" classNames="fade" timeout={300}>
            <SolvedContent onNext={handleSolved} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </OuterPuzzleContainer>
  )
}
