import * as React from 'react'
import { styled } from '../../../lib/styled-components'
import { ChangeEvent, FormEvent, FormEventHandler, useRef, useState } from 'react'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { Navigate, useNavigate } from 'react-router-dom'
import { Utils } from '../../../lib/Utils'
import { Button } from '../../components/Forms'
import { useTranslate } from '../../hooks/useTranslate'

export const AgeCheckForm = () => {
  const store = useApplicationStore()
  const [fieldErrors, setFieldErrors] = useState({})
  const __ = useTranslate()
  const navigate = useNavigate()

  const formRef = useRef<HTMLFormElement>()
  const inputRefs = {
    month: useRef<HTMLInputElement>(),
    day: useRef<HTMLInputElement>(),
    year: useRef<HTMLInputElement>(),
  }

  if (store.isOfDrinkingAge()) {
    return <Navigate to="/intro" />
  }


  const minAge = 18
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const month = parseInt(inputRefs.month.current.value, 10)
    const day = parseInt(inputRefs.day.current.value, 10)
    const year = parseInt(inputRefs.year.current.value, 10)

    if (month < 1 || month > 12) {
      return setFieldErrors({ ...fieldErrors, month: __('ageCheck.invalidDate') })
    }

    if (day < 1 || day > 31) {
      return setFieldErrors({ ...fieldErrors, day: __('ageCheck.invalidDate') })
    }

    if (!Utils.isValidDate(year, month, day)) {
      return setFieldErrors({ ...fieldErrors, year: __('ageCheck.invalidDate') })
    }

    const yearInMs = 3.15576e10
    const birthday = new Date(year, month, day)

    const age = Math.floor((+new Date() - birthday.getTime()) / yearInMs)
    if (age < minAge) {
      return setFieldErrors({
        ...fieldErrors,
        year: Utils.formatText(__('ageCheck.minimumAge'), { minAge }),
      })
    }

    store.age = age
    navigate('/intro')
  }

  const switchToNextInput = (currentInputName: string) => {
    const currentInput = inputRefs[currentInputName].current
    if (!currentInput.checkValidity()) {
      setFieldErrors({ ...fieldErrors, [currentInputName]: __('ageCheck.invalidDate') })
      return
    } else {
      setFieldErrors({ ...fieldErrors, [currentInputName]: null })
    }

    const inputNames = Object.keys(inputRefs)
    const nextInputName = inputNames[inputNames.indexOf(currentInputName) + 1]
    if (nextInputName) {
      inputRefs[nextInputName].current.focus()
    }
  }

  const handleInputChange = (inputName: string, e: ChangeEvent<HTMLInputElement>) => {
    const input = inputRefs[inputName].current
    const value = input.value
    const intValue = parseInt(value, 10)

    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, 4)
    }

    setFieldErrors({ ...fieldErrors, [inputName]: null })

    if (inputName === 'month') {
      if (intValue > 1 || value.length === 2) {
        switchToNextInput(inputName)
      }
    } else if (inputName === 'day') {
      if (intValue > 3 || value.length === 2) {
        switchToNextInput(inputName)
      }
    }
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Inputs>
        <Input
          type="number"
          placeholder={__('ageCheck.month')}
          onChange={handleInputChange.bind(null, 'month')}
          ref={inputRefs.month}
          min="1"
          max="12"
          required
          maxLength={2}
        />
        <Input
          type="number"
          placeholder={__('ageCheck.day')}
          onChange={handleInputChange.bind(null, 'day')}
          ref={inputRefs.day}
          required
          min="1"
          max="31"
          maxLength={2}
        />
        <Input
          type="number"
          placeholder={__('ageCheck.year')}
          max={new Date().getFullYear()}
          onChange={handleInputChange.bind(null, 'year')}
          ref={inputRefs.year}
          maxLength={4}
          required
        />
      </Inputs>
      <Button>{__('ageCheck.submit')}</Button>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 234px;
  margin: 0 auto;
  flex: 1;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
`

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.inputBorderColor};
  text-align: center;
  font-family: ${({ theme }) => theme.boldFontFamily};
  text-transform: uppercase;
  width: 100%;
  font-size: 45px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.textColor};
  border-radius: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    transition: all 0.3s ease-out;
    color: ${({ theme }) => theme.textColor};
  }

  &:focus::placeholder {
    color: transparent;
  }
`
