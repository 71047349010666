import * as React from 'react'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import AppRouter from './AppRouter'
import { ThemeProvider } from 'styled-components'
import { Theme } from '../lib/Theme'
import { ApplicationStoreProvider } from './hooks/useApplicationStore'
import { ApplicationStore } from './stores/ApplicationStore'
import { SoundProvider } from './hooks/useSound'
import { styled } from '../lib/styled-components'
import { useAssetPreloader } from './hooks/useAssetPreloader'
import { LoadingIndicator } from './components/LoadingIndicator'

export const App = () => {
  const store = useMemo(() => new ApplicationStore(), [])
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [sound, setSound] = useState(false)
  const audioRef = useRef<HTMLAudioElement>()
  const loading = useAssetPreloader()

  useEffect(() => {
    if (sound) {
      audioRef.current?.play()
    } else {
      audioRef.current?.pause()
    }
  }, [sound])

  useEffect(() => {
    store.onLanguageUpdate = () => forceUpdate()
  }, [store])

  useEffect(() => {
    new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        document.documentElement.style.setProperty('--webkit-footer-gap', `${entry.contentRect.height}px`)
      })
    }).observe(document.querySelector('.webkit-gap'))
  }, [])

  return (
    <>
      <div className="webkit-gap" />
      <div className="app">
        {loading ? (
          <Loading>
            <LoadingIndicator />
          </Loading>
        ) : (
          <SoundProvider value={{ sound, setSound }}>
            <ThemeProvider theme={Theme}>
              <ApplicationStoreProvider value={store}>
                <AppRouter />
                <audio src="/background-music.mp3" ref={audioRef} loop />
              </ApplicationStoreProvider>
            </ThemeProvider>
          </SoundProvider>
        )}
      </div>
    </>
  )
}

const Loading = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #001301;
  display: flex;
  align-items: center;
  justify-content: center;
`
