import * as React from 'react'
import { css, keyframes, styled } from '../../lib/styled-components'
import { useCallback, useRef, useState } from 'react'
import { Locales } from '../locales'
import { useApplicationStore } from '../hooks/useApplicationStore'
import { useTranslate } from '../hooks/useTranslate'
import { Transition, TransitionGroup, CSSTransition } from 'react-transition-group'

export const LanguageSelector = () => {
  const __ = useTranslate()
  const store = useApplicationStore()
  const [expanded, setExpanded] = useState(false)
  const handleGlobeClick = useCallback(() => {
    setExpanded(true)
  }, [])
  const handleCloseClick = useCallback(() => {
    setExpanded(false)
  }, [])
  const handleLanguageClick = useCallback((localeKey: string) => {
    store.language = localeKey
    setExpanded(false)
  }, [])

  const closeIconRef = useRef<HTMLElement>()
  return (
    <>
      <GlobeIcon src="/images/globe.svg" onClick={handleGlobeClick} />
      <TransitionGroup>
        {expanded && (
          <CSSTransition key={expanded ? 'on' : 'off'} classNames="fade" timeout={300}>
            <Overlay>
              <CloseIcon src="/images/close.svg" onClick={handleCloseClick} />
              <Headline>{__('chooseYourLanguage')}</Headline>
              <LanguageList>
                {Object.keys(Locales).map((localeKey) => (
                  <LanguageItem
                    selected={store.language === localeKey}
                    key={localeKey}
                    onClick={handleLanguageClick.bind(null, localeKey)}
                  >
                    <LanguageFlag src={`/images/flags/${localeKey}.png`} />
                    {Locales[localeKey].name}
                  </LanguageItem>
                ))}
              </LanguageList>
            </Overlay>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

const GlobeIcon = styled.img`
  width: 30px;
  height: 30px;
  position: fixed;
  top: 20px;
  right: 25px;
`

const slideIn = keyframes`
  0% {
    right: 100%;
  }
  100% {
    right: 25px;
  }
`

const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 25px;
  animation: ${slideIn} 0.5s forwards 1;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  border-bottom: 1px solid ${({ theme }) => theme.inputBorderColor};
  padding: 70px 0 20px 0;
  z-index: 2;
  transition: all 0.3s ease-out;
  &.fade-exit {
    opacity: 0;
  }
`

const LanguageList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`

const Headline = styled.div`
  font-family: ${({ theme }) => theme.boldFontFamily};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 1px;
`

const LanguageItem = styled.li<{ selected?: boolean }>`
  font-family: ${({ theme }) => theme.boldFontFamily};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 14px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.highlightTextColor};
      text-decoration: underline;
    `}
`

const LanguageFlag = styled.img`
  margin-right: 15px;
  height: 13px;
`
