import * as React from 'react'
import { styled } from '../../../../lib/styled-components'
import { ChangeEvent, useCallback, useRef } from 'react'

interface IProps {
  onChange?: (value: string) => any
  value?: string
}

export const AnswerInput = ({ onChange, value }: IProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const handleChange = useCallback(
    (characterIndex: number, e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.trim() === '') return

      const character = e.target.value || ' '
      const characters = value.split('')
      characters[characterIndex] = character

      onChange(characters.join(''))

      let nextCharacterIndex = characterIndex + 1
      if (characterIndex === 0) {
        nextCharacterIndex = 2
      } else if (characterIndex === 3) {
        nextCharacterIndex = 5
      }
      inputRefs.current[nextCharacterIndex]?.focus()
    },
    [value]
  )

  const handleKeyUp = useCallback(
    (characterIndex: number, e: KeyboardEvent) => {
      if (e.key === 'Backspace') {
        const wasEmpty = value[characterIndex].trim() === ''

        if (wasEmpty) {
          if (characterIndex === 2) {
            characterIndex = 1
          } else if (characterIndex === 5) {
            characterIndex = 4
          }
          const characters = value.split('')
          characters[characterIndex - 1] = ' '
          onChange(characters.join(''))

          if (characterIndex !== 0) inputRefs.current[characterIndex - 1]?.focus()
        } else {
          const characters = value.split('')
          characters[characterIndex] = ' '
          onChange(characters.join(''))
        }
      }
    },
    [value]
  )

  return (
    <Container>
      <Top>
        <InputGroup>
          <Input
            value={value[0].trim()}
            maxLength={1}
            onChange={handleChange.bind(null, 0)}
            onKeyUp={handleKeyUp.bind(null, 0)}
            ref={(e) => (inputRefs.current[0] = e)}
          />
          <Input value={value[1].trim()} maxLength={1} readOnly />
          <Input
            value={value[2].trim()}
            maxLength={1}
            onChange={handleChange.bind(null, 2)}
            onKeyUp={handleKeyUp.bind(null, 2)}
            ref={(e) => (inputRefs.current[2] = e)}
          />
        </InputGroup>
        <InputGroup>
          <Input
            value={value[3].trim()}
            maxLength={1}
            onChange={handleChange.bind(null, 3)}
            onKeyUp={handleKeyUp.bind(null, 3)}
            ref={(e) => (inputRefs.current[3] = e)}
          />
          <Input value={value[4].trim()} maxLength={1} readOnly />
          <Input
            value={value[5].trim()}
            maxLength={1}
            onChange={handleChange.bind(null, 5)}
            onKeyUp={handleKeyUp.bind(null, 5)}
            ref={(e) => (inputRefs.current[5] = e)}
          />
          <Input
            value={value[6].trim()}
            maxLength={1}
            onChange={handleChange.bind(null, 6)}
            onKeyUp={handleKeyUp.bind(null, 6)}
            ref={(e) => (inputRefs.current[6] = e)}
          />
        </InputGroup>
      </Top>
      <Bottom>
        <InputGroup>
          <Digit>1</Digit>
          <Digit>2</Digit>
          <Digit>3</Digit>
        </InputGroup>
        <InputGroup>
          <Digit>4</Digit>
          <Digit>5</Digit>
          <Digit>6</Digit>
          <Digit>7</Digit>
        </InputGroup>
      </Bottom>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.inputBorderColor};
  text-align: center;
  font-family: ${({ theme }) => theme.boldFontFamily};
  text-transform: uppercase;
  font-size: 30px;
  margin-right: 6px;
  color: ${({ theme }) => theme.textColor};
  width: 35px;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  line-height: 45px;
  padding: 0;

  &:focus {
    outline: none;
    background: rgba(0, 0.074, 0.0039, 0.4);
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
`

const Digit = styled.div`
  width: 35px;
  margin-right: 6px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.boldFontFamily};
  text-align: right;
  padding-top: 5px;
`
