import { TheForestPuzzle } from './app/pages/puzzle/01-the-forest/TheForestPuzzle'
import { SpotTheDifferencePuzzle } from './app/pages/puzzle/02-spot-the-difference/SpotTheDifferencePuzzle'
import { TheMissingIngredientPuzzle } from './app/pages/puzzle/03-the-missing-ingredient/TheMissingIngredientPuzzle'
import { TheLabyrinthPuzzle } from './app/pages/puzzle/04-the-labyrinth/TheLabyrinthPuzzle'
import { TheSecretPuzzle } from './app/pages/puzzle/05-the-secret/TheSecretPuzzle'

export const PuzzleData = [
  { id: 1, number: '01', code: 'N9TT-9G0A-B7FQ-RANC', component: TheForestPuzzle },
  {
    id: 2,
    number: '02',
    code: 'QK6A-JI6S-7ETR-0A6C',
    component: SpotTheDifferencePuzzle,
  },
  {
    id: 3,
    number: '03',
    code: 'SXFP-CHYK-ONI6-S89U',
    component: TheMissingIngredientPuzzle,
  },
  { id: 4, number: '04', code: 'XNSS-HSJW-3NGU-8XTJ', component: TheLabyrinthPuzzle },
  { id: 5, number: '05', code: 'NHLE-L6MI-4GE4-ETEV', component: TheSecretPuzzle },
]
