export const Utils = {
  errorsToTranslationKeys(errors: string[]) {
    return errors.map((error) => {
      switch (error) {
        case 'validation.required':
          return 'requiredField'
        default:
          return 'invalidValue'
      }
    })
  },
  nl2br(text: string): string {
    return text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')
  },
  isValidDate(year, month, day) {
    month = month - 1
    const d = new Date(year, month, day)
    if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
      return true
    }
    return false
  },

  formatText(text: string, args: any = {}): string {
    for (let key in args) {
      text = text.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key])
    }

    text = Utils.nl2br(text.replace(/\{(.*?)\}/gi, '<sub>$1</sub>'))
    text = text.replace(/\[([^\[]+)\]\((.*?)\)/g, '<a href="$2">$1</a>')
    return text
  },
}
