export const Locale = {
  name: 'Bulgarian',
  chooseYourLanguage: 'Choose your language',
  shared: {
    imprint: 'ФИРМЕНИ ДАННИ/ПОВЕРИТЕЛНОСТ',
    backToMainMenu: 'Back to main menu',
    backToHowToPlay: 'Back to "how to play"',
  },
  ageCheck: {
    line1:
      'ТРЯБВА ДА СТЕ НАВЪРШИЛИ ЗАКОННАТА ВЪЗРАСТ ЗА КОНСУМАЦИЯ НА АЛКОХОЛ ВЪВ ВАШАТА СТРАНА НА ДОСТЪП, ЗА ДА ВЛЕЗЕТЕ В ТАЗИ СТРАНИЦА.',
    line2: 'МОЛЯ, ВЪВЕДЕТЕ ВАШАТА ДАТА НА РАЖДАНЕ:',
    invalidDate: 'Invalid date',
    minimumAge: 'ТРЯБВА ДА СТЕ НАВЪРШИЛИ 18 ГОДИНИ, ЗА ДА ПОЛУЧИТЕ ДОСТЪП ДО ТАЗИ СТРАНИЦА.',
    month: 'МЕСЕЦ',
    day: 'ДЕН',
    year: 'ГОДИНА',
    submit: 'ИЗПРАТИ',
  },
  howToPlay: {
    subline: 'КАК ДА',
    headline: 'ИГРАЯ',
    previous: 'Previous',
    next: 'СЛЕДВАЩА',
    firstStep: {
      line1: 'ТРЯБВА ДА РЕШИТЕ ВСИЧКИ ЗАГАДКИ ЕДНА ПО ЕДНА',
      line2: 'СЛЕД КАТО РЕШИТЕ ПЪРВАТА ЗАГАДКА, ВТОРАТА СЛЕДВА АВТОМАТИЧНО.',
    },
    secondStep: {
      line1: 'ОТВОРЕТЕ ЗЛАТНИЯ ПЕЧАТ САМО КОГАТО ВИ ПОМОЛЯТ.',
    },
    thirdStep: {
      line1:
        'АКО СЕ ЗАТРУДНИТЕ И НЕ ЗНАЕТЕ КАКВО ДА НАПРАВИТЕ, МОЖЕТЕ ДА ПОЛУЧИТЕ ПОДСКАЗКА ЗА РЕШАВАНЕ НА ЗАГАДКАТА. ЗА ВСЯКА ЗАГАДКА МОЖЕТЕ ДА ПОЛУЧИТЕ ДВЕ ПОДСКАЗКИ.',
      line2: 'НО НЕ ЗАБРАВЯЙТЕ: ПО-ЗАБАВНО Е БЕЗ ПОДСКАЗКИ!',
    },
    fourthStep: {
      line1:
        'СЕГА ОТВОРЕТЕ ЗЕЛЕНИЯ ПЛИК И ЗАПОЧНЕТЕ С ПЪРВАТА ЗАГАДКА „ГОРАТА“. ПУСНЕТЕ СИ МУЗИКАТА, ЗА ДА СЕ ПОТОПИТЕ В АТМОСФЕРАТА.',
      start: 'НАЧАЛО',
    },
    intro: {
      subline: 'РАЗКРИЙТЕ ТАЙНАТА НА',
      headline: 'Jägermeister',
      line1: 'ДОБРЕ ДОШЛИ В ПРИКЛЮЧЕНСКАТА ИГРА СЪС ЗАГАДКИ НА JÄGERMEISTER',
      line2:
        'ЗА ДА СЕ ЗАБАВЛЯВАТЕ В МАКСИМАЛНА СТЕПЕН, МОЛЯ, ПРОЧЕТЕТЕ ВНИМАТЕЛНО СЛЕДНИТЕ ИНСТРУКЦИИ „КАК ДА ИГРАЯ“, ПРЕДИ ДА ОТВОРИТЕ ЗЕЛЕНИЯ ПЛИК',
      howToPlay: 'КАК ДА ИГРАЯ',
    },
  },
  overview: {
    line: 'ДА ЗАПОЧВАМЕ СЪС ЗАГАДКИТЕ!',
    subline: 'РАЗКРИЙТЕ ТАЙНАТА НА',
    headline: 'JÄGERMEISTER',
  },
  puzzles: {
    subline: 'Puzzle %s',
    '01': {
      title: 'ГОРАТА',
      headline: 'ИЗБЕРЕТЕ ОТГОВОР',
      submit: 'ИЗПРАТИ',
      wrong: {
        headline: 'ГРЕШНО!',
        texts: {
          0: 'ВИЖДАТЕ ЛИ СЕГА ЛИСИЦАТА? ЕТО Я ТАМ!',
          1: 'ВИЖДАТЕ ЛИ СЕГА ЕЛЕНА? ЕТО ГО ТАМ!',
          2: 'ВИЖДАТЕ ЛИ СЕГА БУХАЛА? ЕТО ГО ТАМ!',
        },
        tryAgain: 'ОПИТАЙ ОТНОВО',
      },
      right: {
        headline: 'ПРАВИЛНО!',
        text: 'ГЛИГАНЪТ НЕ Е В ГОРАТА.',
        next: 'СЛЕДВАЩА ИГРА',
      },
      solved: {
        headline: 'СЕГА ОТСТРАНЕТЕ ПЪРВИЯ ЗЛАТЕН ПЕЧАТ, ЗА ДА ПРЕМИНЕТЕ КЪМ СЛЕДВАЩАТА ЗАГАДКА.',
        start: 'ЗАПОЧНЕТЕ ЗАГАДКА 2',
      },
      hints: {
        first: 'НАМЕРЕТЕ ЖИВОТНОТО, КОЕТО НЕ СЕ КРИЕ В ГОРАТА',
        second:
          'ИЗПОЛЗВАЙТЕ СМАРТФОНА СИ И НАПРАВЕТЕ СНИМКА СЪС СВЕТКАВИЦАТА. НА НЕЯ ВЕЧЕ МОЖЕТЕ ДА ВИДИТЕ РАЗЛИЧНИТЕ ОЧИ НА ЖИВОТНИТЕ. КОИ ОЧИ НЕ ВИЖДАТЕ?',
      },
    },
    '02': {
      title: 'ОРЛОВО ОКО',
      headline: 'ИЗБЕРЕТЕ КООРДИНАТИТЕ',
      submit: 'ИЗПРАТИ',
      wrong: {
        headline: 'СЪЖАЛЯВАМЕ, ГРЕШНО! ПОГЛЕДНЕТЕ МАЛКО ПО-ОТБЛИЗО И ОПИТАЙТЕ ОТНОВО',
        tryAgain: 'ОПИТАЙ ОТНОВО',
      },
      right: {
        headline: 'ДА, ПРАВИЛНО, ОРЛОВО ОКО!',
        next: 'СЛЕДВАЩА ИГРА',
      },
      solved: {
        headline: 'СЕГА ОТСТРАНЕТЕ ВТОРИЯ ЗЛАТЕН ПЕЧАТ, ЗА ДА ПРЕМИНЕТЕ КЪМ СЛЕДВАЩАТА ЗАГАДКА.',
        start: 'ЗАПОЧНЕТЕ ЗАГАДКА 3',
      },
      hints: {
        first: 'ОТКРИЙТЕ 3-ТЕ РАЗЛИКИ В ГОРНОТО/ДОЛНОТО ИЗОБРАЖЕНИЕ И ИЗБЕРЕТЕ КООРДИНАТИТЕ.',
      },
    },
    '03': {
      title: 'КОКТЕЙЛ JÄGERMEISTER MULE',
      headline: 'ИЗБЕРЕТЕ ОТГОВОР',
      submit: 'ИЗПРАТИ',
      wrong: {
        headline: 'ГРЕШНО!',
        text: 'ПРОДЪЛЖЕТЕ, ЗА ДА НАМЕРИТЕ ИДЕАЛНАТА РЕЦЕПТА',
        tryAgain: 'ОПИТАЙ ОТНОВО',
      },
      right: {
        headline: 'ПРАВИЛНО!',
        text: 'ВРЕМЕ Е ЗА JÄGERMEISTER MULE!',
        next: 'СЛЕДВАЩА ИГРА',
      },
      solved: {
        headline: 'СЕГА ОТСТРАНЕТЕ ТРЕТИЯ ЗЛАТЕН ПЕЧАТ, ЗА ДА ПРЕМИНЕТЕ КЪМ СЛЕДВАЩАТА ЗАГАДКА',
        start: 'ЗАПОЧНЕТЕ ЗАГАДКА 4',
      },
      hints: {
        first: 'ОТКРИЙТЕ ЛИПСВАЩАТА СЪСТАВКА В ИНТЕРНЕТ',
        second:
          'ПОСЕТЕТЕ **JAGERMEISTER.COM** И РАЗГЛЕДАЙТЕ РЕЦЕПТАТА ЗА JÄGERMEISTER MULE. ПРОСТО ОТКРИЙТЕ ЛИПСВАЩАТА СЪСТАВКА',
      },
    },
    '04': {
      title: 'ТАЙНИ СЪСТАВКИ',
      headline: 'ИЗБЕРЕТЕ ОТГОВОР',
      submit: 'ИЗПРАТИ',
      wrong: {
        headline: 'ГРЕШНО!',
        text: 'ПРОДЪЛЖАВАЙТЕ, ЗА ДА НАМЕРИТЕ ПРАВИЛНАТА СЪСТАВКА',
        tryAgain: 'ОПИТАЙ ОТНОВО',
      },
      right: {
        headline: 'ПРАВИЛНО!',
        text: 'РОЗМАРИНЪТ НЕ Е СЪСТАВКА НА JÄGERMEISTER',
        next: 'СЛЕДВАЩА ИГРА',
      },
      solved: {
        headline: 'СЕГА ОТСТРАНЕТЕ ЧЕТВЪРТИЯ ЗЛАТЕН ПЕЧАТ, ЗА ДА ПРЕМИНЕТЕ КЪМ СЛЕДВАЩАТА ЗАГАДКА',
        start: 'ЗАПОЧНЕТЕ ЗАГАДКА 5',
      },
      hints: {
        first: 'ПРОСЛЕДЕТЕ ЛИНИИТЕ И ОТКРИЙТЕ СЪСТАВКАТА, КОЯТО НЕ Е ЧАСТ ОТ ТАЙНАТА РЕЦЕПТА НА JÄGERMEISTER',
        second: 'КАНЕЛА, КАРДАМОН И ЗВЕЗДОВИДЕН АНАСОН СА СЪСТАВКИ НА JÄGERMEISTER',
      },
    },
    '05': {
      title: 'РАЗКРИЙТЕ ТАЙНАТА',
      headline: 'ВЪВЕДЕТЕ ОТГОВОР',
      codeHeadline: 'ТАЙНАТА Е',
      submit: 'ИЗПРАТИ',
      wrong: {
        headline: 'ГРЕШНО!',
        text: 'ОПИТАЙТЕ ОТНОВО ДА РАЗКРИЕТЕ ТАЙНАТА НА JÄGERMEISTER',
        tryAgain: 'ОПИТАЙ ОТНОВО',
      },
      hints: {
        first: 'ОТСТРАНЕТЕ МОНЕТАТА И СРАВНЕТЕ. ВИЖДАТЕ ЛИ РАЗЛИКИТЕ?',
        second: 'НАМЕРЕТЕ БУКВИТЕ С ЕДНАКВО ИЗОБРАЖЕНИЕ НА МОНЕТАТА И ГИ ПОСТАВЕТЕ НА ПРАВИЛНОТО МЯСТО',
      },
    },
  },
  secret: {
    headline: 'ПОЗДРАВЛЕНИЯ!',
    line1: '**ВИЕ РАЗКРИХТЕ ТАЙНАТА НА JÄGERMEISTER!**',
    line2: 'ВРЕМЕ Е ЗА ЛЕДЕНО СТУДЕН ШОТ!',
    line3: 'БЛАГОДАРИМ ВИ, ЧЕ ИГРАЕТЕ ЕСКЕЙП ИГРАТА НА JÄGERMEISTER.',
    button: 'КАК ДА ИЗПОЛЗВАТЕ МОНЕТАТА',
  },
  headsOrTails: {
    line1: 'ЕЗИ ИЛИ ТУРА?',
    line2: 'ЗЛАТНАТА МОНЕТА ЩЕ РЕШИ КОЙ ЩЕ ПЛАТИ СЛЕДВАЩИЯ РУНД ЛЕДЕНО СТУДЕНИ ШОТОВЕ',
    line3: 'НОСЕТЕ МОНЕТАТА У СЕБЕ СИ ЗА НАЙ-ЗАБАВНИТЕ НОЩИ',
    line4: 'НАЗДРАВЕ!',
  },
}
