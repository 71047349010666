import React from 'react'
import { Heading } from './Typography'
import { styled } from '../../lib/styled-components'

interface IProps {
  smallText?: string
  largeText?: string
}

export const Headline = ({ smallText, largeText }: IProps) => {
  return (
    <Container>
      {smallText && <Heading small>{smallText}</Heading>}
      {largeText && <Heading large>{largeText}</Heading>}
    </Container>
  )
}

const Container = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px;
`
