import * as React from 'react'
import { ApplicationStore } from '../stores/ApplicationStore'

export const SoundContext = React.createContext(null)

export const SoundProvider = SoundContext.Provider

export function useSound(mapStateToProps?: Function): { sound: boolean; setSound: (sound: boolean) => any } {
  const applicationStore = React.useContext(SoundContext)

  if (typeof mapStateToProps !== 'undefined') {
    return mapStateToProps(applicationStore)
  }

  return applicationStore
}
