import * as React from 'react'
import { useMemo } from 'react'
import { PageContainer } from '../../components/PageContainer'
import { Headline } from '../../components/Headline'
import { LargerText } from '../../components/Typography'
import { PuzzleData } from '../../../PuzzleData'
import { styled } from '../../../lib/styled-components'
import { PuzzleItem } from './PuzzleItem'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { useTranslate } from '../../hooks/useTranslate'
import { useDangerousHTML } from '../../hooks/useDangerousHTML'

export const QuizOverviewPage = () => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  const applicationStore = useApplicationStore()
  const visiblePuzzles = useMemo(() => {
    return PuzzleData.slice(
      0,
      PuzzleData.indexOf(PuzzleData.find((p) => p.code === (applicationStore.latestPuzzleCode || PuzzleData[0].code))) +
        1
    )
  }, [applicationStore.latestPuzzleCode])

  return (
    <PageContainer backgroundImage="/images/background.webp" logoSize="small" spreadContent withBackToHowToPlayButton>
      <Headline smallText={__('overview.subline')} largeText={__('overview.headline')} />

      <LargerText bold centered {...h(__('overview.line'))} />
      <PuzzleList>
        {visiblePuzzles.map((puzzle) => (
          <PuzzleItem
            number={puzzle.number}
            label={__(`puzzles.${puzzle.number}.title` as any)}
            path={`/quiz/puzzles/${puzzle.code}`}
            key={puzzle.number}
          />
        ))}
      </PuzzleList>
    </PageContainer>
  )
}

const PuzzleList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  flex: 1;
  justify-content: flex-start;
  margin: 20px 0 0;
`
