import { Locale as ENLocale } from './en'
import { Locale as FRLocale } from './fr'
import { Locale as HULocale } from './hu'
import { Locale as BGLocale } from './bg'
import { Locale as SQLocale } from './sq'
import { Locale as BSLocale } from './bs'
import { Locale as MKLocale } from './mk'

export const Locales = {
  en: ENLocale,
  fr: FRLocale,
  hu: HULocale,
  bg: BGLocale,
  sq: SQLocale,
  bs: BSLocale,
  mk: MKLocale,
}

export type TLocale = typeof ENLocale
