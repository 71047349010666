import { Text } from '../../../components/Typography'
import { Button } from '../../../components/Forms'
import { Link } from 'react-router-dom'
import * as React from 'react'
import { styled } from '../../../../lib/styled-components'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'

export const FirstStepContent = () => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  return (
    <>
      <div>
        <Text bold centered>
          <p {...h(__('howToPlay.firstStep.line1'))} />
          <p {...h(__('howToPlay.firstStep.line2'))} />
        </Text>
      </div>

      <Icon style={{ backgroundImage: "url('/images/how-to-play/steps.svg')" }} />

      <Buttons>
        <NavigationButton as={Link} to="/intro">
          {__('howToPlay.previous')}
        </NavigationButton>
        <NavigationButton as={Link} to="/how-to-play/2">
          {__('howToPlay.next')}
        </NavigationButton>
      </Buttons>
    </>
  )
}

const Icon = styled.div`
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  flex: 1;
  width: 100%;
  margin-bottom: 20px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const NavigationButton = styled(Button)`
  width: 100px;
  &:not(:last-child) {
    margin-right: 15px;
  }
`
