import * as React from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { AgeCheckPage } from './pages/age-check/AgeCheckPage'
import { IntroPage } from './pages/intro/IntroPage'
import { HowToPlayPage } from './pages/how-to-play/HowToPlayPage'
import { QuizOverviewPage } from './pages/quiz-overview/QuizOverviewPage'
import { PuzzlePage } from './pages/puzzle/PuzzlePage'
import { SecretPage } from './pages/secret/SecretPage'
import { ImprintPage } from './pages/imprint/ImprintPage'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { HeadsOrTailsPage } from './pages/heads-or-tails/HeadsOrTailsPage'
import { createGlobalStyle } from '../lib/styled-components'

const AppRoutes = () => {
  const location = useLocation()

  return (<>
    <GlobalStyles />
    <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route path="/" element={<AgeCheckPage />} />
          <Route path="/intro" element={<IntroPage />} />
          <Route path="/how-to-play" element={<HowToPlayPage />} />
          <Route path="/how-to-play/:step" element={<HowToPlayPage />} />
          <Route path="/quiz" element={<QuizOverviewPage />} />
          <Route path="/quiz/puzzles/:code" element={<PuzzlePage />} />
          <Route path="/the-secret-is-ice-cold" element={<SecretPage />} />
          <Route path="/heads-or-tails" element={<HeadsOrTailsPage />} />

          <Route path="/imprint" element={<ImprintPage />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
    </>
  )
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

const GlobalStyles = createGlobalStyle`
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
`
