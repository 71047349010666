import * as React from 'react'
import { useCallback, useState } from 'react'
import { Button } from '../../../components/Forms'
import { Choices } from './Choices'
import { LargerText } from '../../../components/Typography'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'
import { Hints } from '../../../components/Hints'
import { FirstHint } from './hints/FirstHint'
import { SecondHint } from './hints/SecondHint'
import { PuzzleContentContainer } from '../Shared'

interface IProps {
  onSubmit: (value: number) => any
}

export const IngameContent = ({ onSubmit }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()

  const [choice, setChoice] = useState<number>(null)
  const [hintsVisible, setHintsVisible] = useState<boolean>(false)

  const handleSubmit = useCallback(() => {
    if (choice !== null) onSubmit?.(choice)
  }, [choice])

  return (
    <>
      <PuzzleContentContainer hidden={hintsVisible}>
        <LargerText centered bold {...h(__('puzzles.01.headline'))} />
        <Choices choice={choice} onSelect={setChoice} />
        <Button onClick={handleSubmit}>{__('puzzles.01.submit')}</Button>
      </PuzzleContentContainer>
      <Hints
        hintContents={[<FirstHint />, <SecondHint />]}
        onShow={() => setHintsVisible(true)}
        onHide={() => setHintsVisible(false)}
      />
    </>
  )
}
