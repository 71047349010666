import { useLanguage } from './useLanguage'
import { useCallback } from 'react'
import { Locales, TLocale } from '../locales'

type Primitives = string | number | symbol
type Values<T> = T[keyof T]

type Elem = string
type Acc = Record<string, any>

/**
 * Custom user defined typeguard
 */
const hasProperty = <Obj, Prop extends Primitives>(obj: Obj, prop: Prop): obj is Obj & Record<Prop, any> =>
  Object.prototype.hasOwnProperty.call(obj, prop)

/**
 * Obtain value by object property name
 */
type Predicate<Accumulator extends Acc, El extends Elem> = El extends keyof Accumulator ? Accumulator[El] : Accumulator

/**
 * If first argument is empty string, avoid using dot (.)
 * If first argument is non empty string concat two arguments andput dot (.)
 * between them
 */
type Concat<Fst, Scd> = Fst extends string
  ? Scd extends string
    ? Fst extends ''
      ? `${Scd}`
      : `${Fst}.${Scd}`
    : never
  : never
{
}

/**
 * Obtain union of all possible paths
 */
type KeysUnion<T, Cache extends string = ''> = T extends Primitives
  ? Cache
  : {
      [P in keyof T]: Concat<Cache, P> | KeysUnion<T[P], Concat<Cache, P>>
    }[keyof T]

{
}

/**
 * Get object value by path
 */
type GetValueByPath<T extends string, Cache extends Acc = {}> = T extends `${infer Head}.${infer Tail}`
  ? GetValueByPath<Tail, Predicate<Cache, Head>>
  : Predicate<Cache, T>
{
}

/**
 * Obtain union of all possible values,
 * including nested values
 */
type ValuesUnion<T, Cache = T> = T extends Primitives
  ? T
  : Values<{
      [P in keyof T]: Cache | T[P] | ValuesUnion<T[P], Cache | T[P]>
    }>

function deepPickFinal<Obj, Keys extends KeysUnion<Obj>>(obj: ValuesUnion<Obj>, keys: Keys): GetValueByPath<Keys, Obj>

function deepPickFinal<Obj, Keys extends KeysUnion<Obj> & Array<string>>(obj: ValuesUnion<Obj>, ...keys: Keys) {
  return keys.reduce((acc, elem) => (hasProperty(acc, elem) ? acc[elem] : acc), obj)
}

type TCallbackFunction = <Keys extends KeysUnion<TLocale>>(key: Keys, ...args) => GetValueByPath<Keys, TLocale>

const formatString = (str: any, args: string[]) => {
  str = str.replace(/(?:\*\*)([^*<\n]+)(?:\*\*)/g, '<strong>$1</strong>')
  str = str.replace(/%s/gi, args)
  return str
}

export const useTranslate = () => {
  const language = useLanguage()
  return useCallback<TCallbackFunction>(
    (key, ...args) => {
      const path = key.split('.')
      let result = Locales[language],
        i
      for (i = 0; i < path.length; i++) {
        try {
          result = result[path[i]]
        } catch (e) {
          return `Missing translation: ${key}`
        }
      }
      return formatString(result, args)
    },
    [language]
  )
}
