import * as React from 'react'
import { ApplicationStore } from '../stores/ApplicationStore'

export const ApplicationStoreContext = React.createContext(null)

export const ApplicationStoreProvider = ApplicationStoreContext.Provider

export function useApplicationStore(mapStateToProps?: Function): ApplicationStore {
  const applicationStore = React.useContext(ApplicationStoreContext)

  if (typeof mapStateToProps !== 'undefined') {
    return mapStateToProps(applicationStore)
  }

  return applicationStore
}
