import * as React from 'react'
import { PageContainer } from '../../components/PageContainer'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { Navigate, useNavigate } from 'react-router-dom'
import { Heading } from '../../components/Typography'
import { useTranslate } from '../../hooks/useTranslate'
import { useDangerousHTML } from '../../hooks/useDangerousHTML'
import { Text } from '../../components/Typography'
import { styled } from '../../../lib/styled-components'
import { useCallback, useEffect, useRef } from 'react'
import { Button } from '../../components/Forms'

export const SecretPage = () => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  const applicationStore = useApplicationStore()
  const videoRef = useRef<HTMLVideoElement>()
  const audioRef = useRef<HTMLAudioElement>()
  const navigate = useNavigate()
  if (!applicationStore.finishedQuiz) {
    return <Navigate to={`/quiz/puzzles/${applicationStore.latestPuzzleCode}`} />
  }

  const playVideo = useCallback(() => {
    videoRef.current?.play()
    audioRef.current?.play()
  }, [videoRef.current])

  useEffect(() => {
    window.addEventListener('playVideo', playVideo)
    return () => window.removeEventListener('playVideo', playVideo)
  }, [])

  const handleButtonClick = useCallback(() => {
    navigate('/heads-or-tails')
  }, [])

  return (
    <>
      <Audio src="/fanfare.mp3" ref={audioRef} />
      <VideoBackdrop src="/video.mp4" autoPlay playsInline ref={videoRef} />
      <OverlayPageContainer logoSize="small" spreadContent withBackButton>
        <Container>
          <Top>
            <Heading small {...h(__('secret.headline'))} />
            <Text as="p" bold centered {...h(__('secret.line1'))} />
            <Text as="p" bold centered {...h(__('secret.line2'))} />
          </Top>

          <Bottom>
            <Button onClick={handleButtonClick}>{__('secret.button')}</Button>
          </Bottom>
        </Container>
      </OverlayPageContainer>
    </>
  )
}

const Audio = styled.audio`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
`

const VideoBackdrop = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--webkit-footer-gap);
  object-fit: cover;
  z-index: 1;
  object-position: top center;
`

const OverlayPageContainer = styled(PageContainer)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--webkit-footer-gap);
  z-index: 2;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
