import * as React from 'react'
import { useCallback, useState } from 'react'
import { TPuzzleState } from '../../../shared/Types'
import { IngameContent } from './IngameContent'
import { WrongContent } from './WrongContent'
import { RightContent } from './RightContent'
import { SolvedContent } from './SolvedContent'
import { useQuizSolved } from '../../../hooks/useQuizSolved'
import { OuterPuzzleContainer } from '../../../components/OuterPuzzleContainer'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export const TheMissingIngredientPuzzle = () => {
  const [state, setState] = useState<TPuzzleState>('ingame')
  const [choice, setChoice] = useState<number>(null)

  const handleTryAgain = useCallback(() => {
    setState('ingame')
  }, [])

  const handleSolved = useQuizSolved()

  const handleResult = useCallback((choice: number) => {
    if (choice === 3) {
      setState('right')
    } else {
      setChoice(choice)
      setState('wrong')
    }
  }, [])

  return (
    <OuterPuzzleContainer>
      <TransitionGroup>
        {state === 'ingame' && (
          <CSSTransition key="ingame" classNames="fade" timeout={300}>
            <IngameContent onSubmit={handleResult} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {state === 'wrong' && (
          <CSSTransition key="wrong" classNames="fade" timeout={300}>
            <WrongContent onTryAgain={handleTryAgain} choice={choice} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {state === 'right' && (
          <CSSTransition key="right" classNames="fade" timeout={300}>
            <RightContent onNext={() => setState('solved')} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {state === 'solved' && (
          <CSSTransition key="solved" classNames="fade" timeout={300}>
            <SolvedContent onNext={handleSolved} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </OuterPuzzleContainer>
  )
}
