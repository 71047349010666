import * as React from 'react'
import { styled } from '../../../../../lib/styled-components'

export const SecondHint = () => {
  return (
    <Container>
      <img src="/images/puzzles/2/hints/2.svg" />
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
