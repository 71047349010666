import * as React from 'react'
import { styled } from '../../../../lib/styled-components'
import { LargerText, Text } from '../../../components/Typography'
import { Button } from '../../../components/Forms'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'

interface IProps {
  onTryAgain: () => any
  choice: number
}

const HINT_IMAGES = {
  0: '/images/puzzles/1/wrong-fox.webp',
  1: '/images/puzzles/1/wrong-deer.webp',
  2: '/images/puzzles/1/wrong-owl.webp',
}

export const WrongContent = ({ onTryAgain, choice }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()

  return (
    <Container>
      <Top>
        <LargerText bold centered>
          <p {...h(__('puzzles.01.wrong.headline'))} />
        </LargerText>
        <Text bold centered>
          <p {...h(__(('puzzles.01.wrong.texts.' + choice) as any))} />
        </Text>

        <Image src={HINT_IMAGES[choice]} />
      </Top>

      <Button onClick={onTryAgain}>{__('puzzles.01.wrong.tryAgain')}</Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

const Image = styled.img`
  width: 202px;
`
