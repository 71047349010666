import { styled } from '../../lib/styled-components'

export const Button = styled.button`
  height: 40px;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.boldFontFamily};
  text-transform: uppercase;
  letter-spacing: 1px;
  max-width: 215px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 12px;
`
