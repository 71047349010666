import { Text } from '../../../components/Typography'
import { Button } from '../../../components/Forms'
import { Link } from 'react-router-dom'
import * as React from 'react'
import { styled } from '../../../../lib/styled-components'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'

export const SecondStepContent = () => {
  const __ = useTranslate()
  const h = useDangerousHTML()
  return (
    <>
      <div>
        <Text bold centered>
          <p {...h(__('howToPlay.secondStep.line1'))} />
        </Text>
      </div>

      <IconContainer>
        <Icon src="/images/how-to-play/golden-seal.webp" />
      </IconContainer>

      <Buttons>
        <NavigationButton as={Link} to="/how-to-play/1">
          {__('howToPlay.previous')}
        </NavigationButton>
        <NavigationButton as={Link} to="/how-to-play/3">
          {__('howToPlay.next')}
        </NavigationButton>
      </Buttons>
    </>
  )
}

const Icon = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  max-width: 80%;
  transform: translate(-50%, -50%);
`

const IconContainer = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const NavigationButton = styled(Button)`
  width: 100px;
  &:not(:last-child) {
    margin-right: 15px;
  }
`
