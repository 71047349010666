import * as React from 'react'
import { useCallback, useState } from 'react'
import { CodeContent } from './CodeContent'
import { WrongContent } from './WrongContent'
import { useNavigate } from 'react-router-dom'
import { useApplicationStore } from '../../../hooks/useApplicationStore'
import { OuterPuzzleContainer } from '../../../components/OuterPuzzleContainer'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

export const TheSecretPuzzle = () => {
  const navigate = useNavigate()
  const store = useApplicationStore()
  const [state, setState] = useState<'code' | 'wrong'>('code')

  const handleCodeSubmit = useCallback((code: string) => {
    setTimeout(() => {
      window.dispatchEvent(new Event('playVideo'))
    }, 500)
    if (code.toLowerCase() === 'icecold') {
      store.finishQuiz()
      navigate('/the-secret-is-ice-cold')
    } else {
      setState('wrong')
    }
  }, [])

  const handleTryAgain = useCallback(() => {
    setState('code')
  }, [])

  return (
    <OuterPuzzleContainer>
      <TransitionGroup>
        {state === 'code' && (
          <CSSTransition key="code" classNames="fade" timeout={300}>
            <CodeContent onSubmit={handleCodeSubmit} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {state === 'wrong' && (
          <CSSTransition key="wrong" classNames="fade" timeout={300}>
            <WrongContent onTryAgain={handleTryAgain} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </OuterPuzzleContainer>
  )
}
