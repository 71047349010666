import * as React from 'react'
import { Heading, LargerText, Text } from '../../../components/Typography'
import { useCallback, useState } from 'react'
import { AnswerInput } from './AnswerInput'
import { Headline } from '../../../components/Headline'
import { Button } from '../../../components/Forms'
import { styled } from '../../../../lib/styled-components'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'
import { PuzzleContentContainer } from '../Shared'
import { Hints } from '../../../components/Hints'
import { FirstHint } from './hints/FirstHint'
import { SecondHint } from './hints/SecondHint'

interface IProps {
  onSubmit?: (code: string) => any
}

export const CodeContent = ({ onSubmit }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()

  const [answer, setAnswer] = useState<string>(' C  O  ')
  const [hintsVisible, setHintsVisible] = useState<boolean>(false)

  const handleChange = useCallback((answer) => {
    setAnswer(answer)
  }, [])
  const handleSubmit = useCallback(() => {
    onSubmit?.(answer)
  }, [answer])

  return (
    <>
      <PuzzleContentContainer hidden={hintsVisible}>
        <LargerText bold centered {...h(__('puzzles.05.headline'))} />

        <div>
          <Heading large noUnderline {...h(__('puzzles.05.codeHeadline'))} />
          <AnswerInput onChange={handleChange} value={answer} />
        </div>

        <Button onClick={handleSubmit}>{__('puzzles.05.submit')}</Button>
      </PuzzleContentContainer>
      <Hints
        hintContents={[<FirstHint />, <SecondHint />]}
        onShow={() => setHintsVisible(true)}
        onHide={() => setHintsVisible(false)}
      />
    </>
  )
}
