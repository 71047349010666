import { useCallback } from 'react'
import { useApplicationStore } from './useApplicationStore'
import { useNavigate } from 'react-router-dom'

export const useQuizSolved = () => {
  const store = useApplicationStore()
  const navigate = useNavigate()

  return useCallback(() => {
    if (store.finishedQuiz) {
      return navigate('/the-secret-is-ice-cold')
    }
    const nextCode = store.nextPuzzle()
    navigate(`/quiz/puzzles/${nextCode}`)
  }, [])
}
