import * as React from 'react'
import { keyframes } from 'styled-components'
import styled from 'styled-components'

interface IProps {
  tiny?: boolean
  className?: string
}

export const LoadingIndicator = ({ tiny, className }: IProps) => {
  return <Container {...{ tiny, className }} />
}

const RingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.div<{ tiny?: boolean }>`
  display: inline-block;
  width: 34px;
  height: 34px;

  &:after {
    content: ' ';
    display: block;
    width: 24px;
    height: 24px;
    margin: 4px;
    border-radius: 50%;
    border: 2px solid white;
    border-color: white transparent white transparent;
    animation: ${RingAnimation} 1.2s linear infinite;
  }
`
