export const Locale = {
  name: 'French',
  chooseYourLanguage: 'Choose your language',
  shared: {
    imprint: 'MENTIONS LÉGALES/VIE PRIVÉE',
    backToMainMenu: 'RETOUR AU MENU PRINCIPAL',
    backToHowToPlay: 'Back to "how to play"',
  },
  ageCheck: {
    line1: 'POUR ACCÉDER À CETTE PAGE, VOUS DEVEZ ÊTRE EN ÂGE LÉGAL DE CONSOMMER DE L’ALCOOL DANS VOTRE PAYS D’ACCÈS.',
    line2: 'VEUILLEZ ENTRER VOTRE DATE DE NAISSANCE:',
    invalidDate: 'Invalid date',
    minimumAge: 'VOUS DEVEZ AVOIR 18 ANS OU PLUS POUR ACCÉDER À CETTE PAGE.',
    month: 'MOIS',
    day: 'JOUR',
    year: 'ANNÉE',
    submit: 'SOUMETTRE',
  },
  howToPlay: {
    subline: 'COMMENT',
    headline: 'play',
    previous: 'Previous',
    next: 'SUIVANT',
    firstStep: {
      line1: 'VOUS DEVEZ RÉSOUDRE TOUTES LES ÉNIGMES UNE PAR UNE',
      line2: 'LORSQUE VOUS AUREZ RÉSOLU LA PREMIÈRE ÉNIGME, LA DEUXIÈME SUIVRA AUTOMATIQUEMENT.',
    },
    secondStep: {
      line1:
        'OUVREZ L’ENVELOPPE VERTE POUR RÉSOUDRE LA PREMIÈRE ÉNIGME « LA FORÊT ». ACTIVEZ LA MUSIQUE POUR VOUS METTRE DANS L’AMBIANCE',
    },
    thirdStep: {
      line1:
        'SI VOUS NE SAVEZ PLUS QUOI FAIRE, VOUS POUVEZ DEMANDER UN INDICE. **VOUS AVEZ DROIT À DEUX INDICES POUR CHAQUE ÉNIGME**',
      line2: 'MAIS N’OUBLIEZ PAS: C’EST PLUS AMUSANT SANS INDICES!',
    },
    fourthStep: {
      line1:
        'OUVREZ L’ENVELOPPE VERTE POUR RÉSOUDRE LA PREMIÈRE ÉNIGME **«LA FORÊT»**. ACTIVEZ LA MUSIQUE POUR VOUS METTRE DANS L’AMBIANCE',
      start: 'COMMENCER',
    },
    intro: {
      subline: 'DÉCOUVREZ LE SECRET DE',
      headline: 'Jägermeister',
      line1: 'BIENVENUE DANS **L’ESCAPE GAME DE JÄGERMEISTER**',
      line2:
        'POUR PROFITER DU JEU, LISEZ ATTENTIVEMENT LES **INSTRUCTIONS** SUIVANTES AVANT D’OUVRIR L’ENVELOPPE **VERTE**',
      howToPlay: 'COMMENT JOUER',
    },
  },
  overview: {
    line: 'COMMENCEZ À RÉSOUDRE LES ÉNIGMES!',
    subline: 'DÉCOUVREZ LE SECRET DE',
    headline: 'Jägermeister',
  },
  puzzles: {
    subline: 'Puzzle %s',
    '01': {
      title: 'LA FORÊT',
      headline: 'CHOISISSEZ VOTRE RÉPONSE',
      submit: 'SOUMETTRE',
      wrong: {
        headline: 'CE N’EST PAS ÇA !',
        texts: {
          0: 'VOUS VOYEZ LE RENARD À PRÉSENT ? IL EST JUSTE LÀ !',
          1: 'VOUS VOYEZ LE CERF À PRÉSENT ? IL EST JUSTE LÀ !',
          2: 'VOUS VOYEZ LE HIBOU À PRÉSENT ? IL EST JUSTE LÀ !',
        },
        tryAgain: 'RÉESSAYER',
      },
      right: {
        headline: 'BONNE RÉPONSE !',
        text: "LE SANGLIER N'EST PAS DANS LA FORÊT.",
        next: 'JEU SUIVANT',
      },
      solved: {
        headline: 'RETIREZ LE PREMIER SCEAU DORÉ POUR PASSER À L’ÉNIGME SUIVANTE.',
        start: 'PASSER À L’ÉNIGME 2',
      },
      hints: {
        first: 'TROUVEZ L’ANIMAL QUI NE SE CACHE PAS DANS LA FORÊT',
        second:
          'UTILISEZ VOTRE SMARTPHONE POUR PRENDRE UNE PHOTO AVEC LE FLASH. VOUS VERREZ PLUSIEURS YEUX D’ANIMAUX. QUEL EST L’ANIMAL DONT LES YEUX N’APPARAISSENT PAS SUR LA PHOTO ?',
      },
    },
    '02': {
      title: 'ŒIL D’AIGLE',
      headline: 'SÉLECTIONNEZ LES COORDONNÉES',
      submit: 'SOUMETTRE',
      wrong: {
        headline: 'OUPS, MAUVAISE RÉPONSE. REGARDEZ MIEUX ET RÉESSAYEZ',
        tryAgain: 'RÉESSAYER',
      },
      right: {
        headline: 'OUI, C’EST EXACT, ŒIL D’AIGLE !',
        next: 'JEU SUIVANT',
      },
      solved: {
        headline: 'RETIREZ LE DEUXIÈME SCEAU DORÉ POUR PASSER À L’ÉNIGME SUIVANTE.',
        start: 'PASSER À L’ÉNIGME 3',
      },
      hints: {
        first: 'REPÉREZ LES 3 DIFFÉRENCES ENTRE L’IMAGE DU HAUT ET L’IMAGE DU BAS ET SÉLECTIONNEZ LES COORDONNÉES.',
      },
    },
    '03': {
      title: 'Jägermeister Mule',
      headline: 'CHOISISSEZ VOTRE RÉPONSE',
      submit: 'SOUMETTRE',
      wrong: {
        headline: 'CE N’EST PAS ÇA !',
        text: 'CONTINUEZ POUR DÉCOUVRIR LA RECETTE PARFAITE',
        tryAgain: 'RÉESSAYER',
      },
      right: {
        headline: 'C’EST EXACT !',
        text: 'C’EST L’HEURE D’UN JÄGERMEISTER MULE !',
        next: 'JEU SUIVANT',
      },
      solved: {
        headline: 'RETIREZ LE TROISIÈME SCEAU DORÉ POUR PASSER À L’ÉNIGME SUIVANTE',
        start: 'PASSER À L’ÉNIGME 4',
      },
      hints: {
        first: 'TROUVEZ L’INGRÉDIENT MANQUANT SUR INTERNET',
        second:
          'RENDEZ-VOUS SUR **JAGERMEISTER.COM** ET CONSULTEZ LA RECETTE DU COCKTAIL JÄGERMEISTER MULE. SÉLECTIONNEZ L’INGRÉDIENT MANQUANT',
      },
    },
    '04': {
      title: 'INGRÉDIENTS SECRETS',
      headline: 'CHOISISSEZ VOTRE RÉPONSE',
      submit: 'SOUMETTRE',
      wrong: {
        headline: 'CE N’EST PAS ÇA !',
        text: 'CONTINUEZ POUR TROUVER LE BON INGRÉDIENT',
        tryAgain: 'RÉESSAYER',
      },
      right: {
        headline: 'C’EST EXACT !',
        text: 'LE ROMARIN N’EST PAS UN INGRÉDIENT DU JÄGERMEISTER',
        next: 'JEU SUIVANT',
      },
      solved: {
        headline: 'RETIREZ LE QUATRIÈME SCEAU DORÉ POUR PASSER À L’ÉNIGME SUIVANTE',
        start: 'PASSER À L’ÉNIGME 5',
      },
      hints: {
        first:
          'TRACEZ LES LIGNES ET DÉCOUVREZ L’INGRÉDIENT QUI NE FAIT PAS PARTIE DE LA RECETTE SECRÈTE DU JÄGERMEISTER',
        second: 'LA CANNELLE, LA CARDAMOME ET L’ANIS ÉTOILÉ SONT DES INGRÉDIENTS DU JÄGERMEISTER',
      },
    },
    '05': {
      title: 'DÉCOUVREZ LE SECRET',
      headline: 'ENTREZ VOTRE RÉPONSE',
      codeHeadline: 'LE SECRET EST',
      submit: 'SOUMETTRE',
      wrong: {
        headline: 'CE N’EST PAS ÇA',
        text: 'ESSAYEZ ENCORE POUR DÉCOUVRIR LE SECRET DU JÄGERMEISTER',
        tryAgain: 'RÉESSAYER',
      },
      hints: {
        first: 'RETIREZ LA PIÈCE ET COMPAREZ. VOYEZ-VOUS DES DIFFÉRENCES ?',
        second: 'TROUVEZ LES LETTRES AVEC LA MÊME IMAGE DE LA PIÈCE ET PLACEZ-LES AU BON ENDROIT',
      },
    },
  },
  secret: {
    headline: 'FÉLICITATIONS !',
    line1: '**VOUS AVEZ DÉCOUVERT LE SECRET DU JÄGERMEISTER !**',
    line2: 'C’EST LE MOMENT DE SAVOURER UN SHOT GLACÉ !',
    line3: 'MERCI D\'AVOIR JOUÉ AU JEU D\'ÉVASION JÄGERMEISTER.',
    button: 'COMMENT UTILISER LA PIÈCE',
  },
  headsOrTails: {
    line1: 'PILE OU FACE ?',
    line2: 'LAISSEZ LA PIÈCE D’OR DÉCIDER QUI PAIERA LA PROCHAINE TOURNÉE DE SHOTS GLACÉS',
    line3: 'GARDEZ LA PIÈCE SUR VOUS POUR VOS MEILLEURES SOIRÉES',
    line4: 'Prost !',
  },
}
