import { styled } from '../../../lib/styled-components'

export const PuzzleContentContainer = styled.div<{ hidden?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-out;
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
