import * as React from 'react'
import { useMemo } from 'react'
import { PageContainer } from '../../components/PageContainer'
import { Headline } from '../../components/Headline'
import { useApplicationStore } from '../../hooks/useApplicationStore'
import { Navigate, useParams } from 'react-router-dom'
import { PuzzleData } from '../../../PuzzleData'
import { useTranslate } from '../../hooks/useTranslate'
import { styled } from '../../../lib/styled-components'

export const PuzzlePage = () => {
  const __ = useTranslate()
  const applicationStore = useApplicationStore()
  const { code } = useParams<{ code: string }>()
  const puzzleData = useMemo(() => {
    return PuzzleData.find((p) => p.code === code)
  }, [code])

  if (!applicationStore.canSeePuzzleWithCode(code)) {
    return <Navigate to={`/quiz/puzzles/${applicationStore.latestPuzzleCode}`} />
  }

  if (!puzzleData) return <div />

  return (
    <PageContainer
      backgroundImage={`/images/puzzles/${puzzleData.id}/background.webp`}
      logoSize="small"
      spreadContent
      withBackButton
    >
      <Headline
        smallText={__('puzzles.subline', puzzleData.number)}
        largeText={__(('puzzles.' + puzzleData.number + '.title') as any)}
      />
      <ContentContainer>
        <puzzleData.component />
      </ContentContainer>
    </PageContainer>
  )
}

const ContentContainer = styled.div`
    position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  `
