import * as React from 'react'
import { useCallback, useState } from 'react'
import { Button } from '../../../components/Forms'
import { LargerText } from '../../../components/Typography'
import { Grid } from './Grid'
import { useTranslate } from '../../../hooks/useTranslate'
import { useDangerousHTML } from '../../../hooks/useDangerousHTML'
import { FirstHint } from './hints/FirstHint'
import { SecondHint } from './hints/SecondHint'
import { Hints } from '../../../components/Hints'
import { PuzzleContentContainer } from '../Shared'

interface IProps {
  onSubmit: (result: [string, number][]) => any
}

export const IngameContent = ({ onSubmit }: IProps) => {
  const __ = useTranslate()
  const h = useDangerousHTML()

  const [cells, setCells] = useState<[string, number][]>([])
  const [hintsVisible, setHintsVisible] = useState<boolean>(false)

  const handleCellClick = (row: string, cell: number) => {
    setCells((cells) => {
      const existingCell = cells.find(([r, c]) => r === row && c === cell)
      const cellIndex = cells.indexOf(existingCell)
      if (cellIndex === -1) {
        return [...cells, [row, cell]]
      } else {
        return [...cells.slice(0, cellIndex), ...cells.slice(cellIndex + 1)]
      }
    })
  }

  const handleSubmit = useCallback(() => {
    if (cells.length) onSubmit?.(cells)
  }, [cells])

  return (
    <>
      <PuzzleContentContainer hidden={hintsVisible}>
        <LargerText centered bold {...h(__('puzzles.02.headline'))} />

        <Grid onCellClick={handleCellClick} cells={cells} />

        <Button onClick={handleSubmit}>{__('puzzles.02.submit')}</Button>
      </PuzzleContentContainer>
      <Hints
        hintContents={[<FirstHint />, <SecondHint />]}
        onShow={() => setHintsVisible(true)}
        onHide={() => setHintsVisible(false)}
      />
    </>
  )
}
