import * as React from 'react'
import { styled } from '../../lib/styled-components'

export type TLogoSize = 'large' | 'small'

interface IProps {
  size?: TLogoSize
}

export const Logo = ({ size }: IProps) => {
  return <LogoImage src={size === 'large' ? '/images/logo-large.webp' : '/images/logo-small.webp'} {...{ size }} />
}

const LogoImage = styled.img<{ size?: TLogoSize }>`
  width: ${({ size }) => (size === 'large' ? '135px' : '77px')};
  margin: auto;
  margin-bottom: 35px;
`
