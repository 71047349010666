import * as React from 'react'
import { Text } from '../../components/Typography'
import { PageContainer } from '../../components/PageContainer'
import { AgeCheckForm } from './AgeCheckForm'
import { useTranslate } from '../../hooks/useTranslate'

export const AgeCheckPage = () => {
  const __ = useTranslate()
  return (
    <PageContainer backgroundImage="/images/background.webp" logoSize="large">
      <Text centered bold>
        <p>{__('ageCheck.line1')}</p>
        <p>{__('ageCheck.line2')}</p>
      </Text>

      <AgeCheckForm />
    </PageContainer>
  )
}
