export const Locale = {
  name: 'Bosnian',
  chooseYourLanguage: 'Choose your language',
  shared: {
    imprint: 'OZNAKA/PRIVATNOST',
    backToMainMenu: 'POVRATAK NA GLAVNI MENI',
    backToHowToPlay: 'Back to "how to play"',
  },
  ageCheck: {
    line1:
      'DA BISTE PRISTUPILI OVOJ STRANICI MORATE IMATI NAJMANJI BROJ GODINA ZA DOZVOLJENU KONZUMACIJU ALKOHOLA U VAŠOJ ZEMLJI ILI VIŠE.',
    line2: 'MOLIMO UNESITE VAŠ DATUM ROĐENJA:',
    invalidDate: 'Invalid date',
    minimumAge: 'MORATE IMATI 18 ILI STARIJI DA PRISTUPITE OVOJ STRANICI.',
    month: 'MJESEC',
    day: 'DAN',
    year: 'GODINA',
    submit: 'PODNESITE',
  },
  howToPlay: {
    subline: 'PRAVILA',
    headline: 'IGRE',
    previous: 'Previous',
    next: 'SLJEDEĆE',
    firstStep: {
      line1: 'TREBA DA RIJEŠITE SVE SLAGALICE **JEDNU PO JEDNU**',
      line2: 'KADA RIJEŠITE PRVU SLAGALICU, DRUGA ĆE AUTOMATSKI IĆI IZA NJE.',
    },
    secondStep: {
      line1: 'OTVORITE ZLATNI PEČAT TEK KADA BUDETE ZAMOLJENI.',
    },
    thirdStep: {
      line1:
        'AKO SE ZAGLAVITE I NE ZNATE ŠTA DA RADITE, MOŽETE DOBITI NAGOVJEŠTAJ KAKO DA RIJEŠITE SLAGALICU. ZA SVAKU SLAGALICU MOŽETE DOBITI DVA NAGOVJEŠTAJA',
      line2: 'ALI NE ZABORAVITE: ZABAVNIJE JE BEZ NAGOVJEŠTAJA!',
    },
    fourthStep: {
      line1: 'SADA OTVORITE ZELENI KOVERAT I POČNITE S PRVOM SLAGALICOM „ŠUMA“. PUSTITE MUZIKU ZA ATMOSFERU',
      start: 'POČETAK ',
    },
    intro: {
      subline: 'OTKRIJTE TAJNU',
      headline: 'JÄGERMEISTERA',
      line1: 'DOBRODOŠLI U JÄGERMEISTER IGRU BEKSTVA',
      line2:
        'ZA MAKSIMALNU ZABAVU MOLIMO VAS DA PAŽLJIVO PROČITATE UPUTSTVO „PRAVILA IGRE“ PRIJE NEGO ŠTO OTVORITE ZELENI KOVERAT',
      howToPlay: 'PRAVILA IGRE',
    },
  },
  overview: {
    line: 'POČNIMO SA SLAGALICAMA!',
    subline: 'OTKRIJTE TAJNU',
    headline: 'JÄGERMEISTERA',
  },
  puzzles: {
    subline: 'Puzzle %s',
    '01': {
      title: 'ŠUMA',
      headline: 'IZABERITE ODGOVOR ',
      submit: 'PODNESITE',
      wrong: {
        headline: 'NETAČNO!',
        texts: {
          0: 'DA LI SADA VIDITE LISICU? EVO JE!',
          1: 'DA LI SADA VIDITE JELENA? EVO GA!',
          2: 'DA LI SADA VIDITE SOVU? EVO JE!',
        },
        tryAgain: 'POKUŠAJTE PONOVO',
      },
      right: {
        headline: 'TAČNO!',
        text: 'DIVLJA SVINJA NIJE U ŠUMI.',
        next: 'SLJEDEĆA IGRA',
      },
      solved: {
        headline: 'SADA UKLONITE PRVI ZLATNI PEČAT KAKO BISTE PREŠLI NA SLJEDEĆU SLAGALICU.',
        start: 'POKRENITE SLAGALICU 2',
      },
      hints: {
        first: 'PRONAĐITE ŽIVOTINJU KOJA SE NE KRIJE U ŠUMI',
        second:
          'KORISTITE SVOJ PAMETNI MOBITEL I NAPRAVITE FOTOGRAFIJU UZ BLIC. NA FOTOGRAFIJI MOŽETE VIDJETI OČI DRUGE ŽIVOTINJE. KOJE OČI NEĆETE VIDJETI?',
      },
    },
    '02': {
      title: 'ORLOVO OKO',
      headline: 'ODABERITE KOORDINATE',
      submit: 'PODNESITE',
      wrong: {
        headline: 'ŽAO NAM JE, NETAČNO. POGLEDAJTE PAŽLJIVIJE I PONOVO POKUŠAJTE',
        tryAgain: 'POKUŠAJTE PONOVO',
      },
      right: {
        headline: 'DA, TAKO JE, ORLOVO OKO!',
        next: 'SLJEDEĆA IGRA',
      },
      solved: {
        headline: 'SADA UKLONITE DRUGI ZLATNI PEČAT KAKO BISTE PREŠLI NA SLJEDEĆU SLAGALICU.',
        start: 'POKRENITE SLAGALICU 3',
      },
      hints: {
        first: 'PRONAĐITE 3 RAZLIKE NA GORNJOJ/DONJOJ SLICI I ODABERITE KOORDINATE.',
      },
    },
    '03': {
      title: 'Jägermeister Mule',
      headline: 'IZABERITE ODGOVOR',
      submit: 'PODNESITE',
      wrong: {
        headline: 'NETAČNO!',
        text: 'NASTAVITE KAKO BISTE PRONAŠLI SAVRŠENI RECEPT',
        tryAgain: 'POKUŠAJTE PONOVO',
      },
      right: {
        headline: 'TAČNO!',
        text: 'VRIJEME JE ZA JÄGERMEISTER MULE!',
        next: 'SLJEDEĆA IGRA',
      },
      solved: {
        headline: 'SADA UKLONITE TREĆI ZLATNI PEČAT KAKO BISTE PREŠLI NA SLJEDEĆU SLAGALICU',
        start: 'POKRENITE SLAGALICU 4',
      },
      hints: {
        first: 'PRONAĐITE SASTOJAK KOJI NEDOSTAJE NA INTERNETU',
        second:
          'POSJETITE JAGERMEISTER.COM I POTRAŽITE RECEPT ZA JÄGERMEISTER MULE. SAMO ODABERITE SASTOJAK KOJI NEDOSTAJE',
      },
    },
    '04': {
      title: 'TAJNI SASTOJCI',
      headline: 'IZABERITE ODGOVOR',
      submit: 'PODNESITE',
      wrong: {
        headline: 'NETAČNO!',
        text: 'NASTAVITE KAKO BISTE PRONAŠLI PRAVI SASTOJAK',
        tryAgain: 'POKUŠAJTE PONOVO',
      },
      right: {
        headline: 'TAČNO!',
        text: 'RUZMARIN NIJE SASTOJAK JÄGERMEISTERA',
        next: 'SLJEDEĆA IGRA',
      },
      solved: {
        headline: 'SADA UKLONITE ČETVRTI ZLATNI PEČAT KAKO BISTE PREŠLI NA SLJEDEĆU SLAGALICU',
        start: 'POKRENITE SLAGALICU 5',
      },
      hints: {
        first: 'PRATITE LINIJE I PRONAĐITE SASTOJAK KOJI NIJE DIO TAJNOG JÄGERMEISTER RECEPTA',
        second: 'CIMET, KARDAMOM I ZVJEZDASTI ANIS SU SASTOJCI JÄGERMEISTERA',
      },
    },
    '05': {
      title: 'OTKRIJTE TAJNU',
      headline: 'UNESITE ODGOVOR',
      codeHeadline: 'TAJNA JE',
      submit: 'PODNESITE',
      wrong: {
        headline: 'NETAČNO',
        text: 'POKUŠAJTE PONOVO KAKO BISTE OTKRILI TAJNU JÄGERMEISTERA',
        tryAgain: 'POKUŠAJTE PONOVO',
      },
      hints: {
        first: 'UKLONITE NOVČIĆ I UPOREDITE. DA LI VIDITE IKAKVE RAZLIKE?',
        second: 'PRONAĐITE SLOVA SA ISTOM SLIKOM NA NOVČIĆU I STAVITE IH NA PRAVO MJESTO',
      },
    },
  },
  secret: {
    headline: 'ČESTITAMO!',
    line1: '**OTKRILI STE TAJNU JÄGERMEISTERA!**',
    line2: 'VRIJEME JE ZA LEDENI ŠOT!',
    line3: 'HVALA ŠTO IGRATE IGRCU JÄGERMEISTER ESCAPE.',
    button: 'KAKO DA KORISTITE NOVČIĆ',
  },
  headsOrTails: {
    line1: 'PISMO ILI GLAVA?',
    line2: 'ZLATNI NOVČIĆ ĆE ODLUČITI KO PLAĆA SLJEDEĆU TURU LEDENIH ŠOTOVA ',
    line3: 'ČUVAJTE NOVČIĆ - ZA VAŠE NAJLJEPŠE NOĆI',
    line4: 'ŽIVJELI!',
  },
}
